import { Component, OnInit } from '@angular/core';
import {enableProdMode} from '@angular/core';
import { TweenLite,  TimelineMax, TimelineLite, Back, Power0, Power4} from 'gsap'; 
import{ ExpoScaleEase} from 'gsap/EasePack.js'
import {ScrollTrigger } from 'gsap/ScrollTrigger.js';
import MorphSVGPlugin from 'gsap/MorphSVGPlugin';







import * as $ from 'jquery';
import { removeData } from 'jquery';
import { AnimationFrameScheduler } from 'rxjs/internal/scheduler/AnimationFrameScheduler';
import { browser } from 'protractor';
enableProdMode();


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  title = 'xwebdevelopment';

  status: boolean = false;



public ngOnInit()
{

  if (localStorage.getItem('cookieSeen') != 'shown') {

    this.status = !this.status; 
 


  };

/*
  if (localStorage.getItem('cookieSeen') == 'notshown') {


    document.cookie = "_ga = ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
    document.cookie = "_gid = ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
    document.cookie = "_gat = ; expires = Thu, 01 Jan 1970 00:00:00 GMT"; 

  };
 */ 
 
  $('.cookie-consent-banner__cta').click(function() {
    localStorage.setItem('cookieSeen','shown')
  }) 

  /*

  $('.cookie-consent-banner__cta--secondary').click(function() {
 
    localStorage.setItem('cookieSeen','notshown')

    document.cookie = "_ga = ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
    document.cookie = "_gid = ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
    document.cookie = "_gat = ; expires = Thu, 01 Jan 1970 00:00:00 GMT"; 
  }) 

    
*/



  $(document).ready(function(){

 


$( ".button span" ).hover(
  function() {
    $( this ).addClass( "dropdown-show" );
  }, function() {
    $( this ).removeClass( "dropdown-show" );
  }
);

$( ".contact-area" ).hover(
  function() {
    $(".me").addClass( "oja" );
  }, function() {
    $(".me" ).removeClass( "oja" );
  }
);


var tls = new TimelineMax({
yoyo: false,
force3D:false
});
/*
ScrollTrigger.create({
  trigger: "#box8",
  start: "top top",
  endTrigger: "#box6",
  end: "bottom 50%+=100px",
  onToggle: self => console.log("toggled, isActive:", self.isActive),
  onUpdate: self => {
    console.log("progress:", self.progress.toFixed(3), "direction:", self.direction, "velocity", self.getVelocity());
  }
});

*/

 // var $menuwindow = $('#menuwindow')
  //var $menubutton = $('.menubutton')
  //var $logo = $('#logo')
  //var $topline = $('.topline')
  //var $middleline = $('.middleline')
 // var $bottomline = $('.bottomline')
//var $html = $('.nicex')
//var $htmlx = $('#cnavigationmodal')
//  var $activemenuclass = $('.activemenuclass')
//var topbar = $('#topbar')

  //var $menuwindowframe2TL = new TimelineMax({paused:true, reversed: true});


  //var $menuwindowframeTL = new TimelineLite({paused:true, reversed: true});
  //$menubutton.on("click", function(e) {$menuwindowframeTL.reversed() ? $menuwindowframeTL.play() : $menuwindowframeTL.reverse();});
 // $menuwindowframeTL
  //.fromTo($menuwindow,0.3, {css:{top:"100%", opacity: 0 }}, {top:"90px", opacity: 1, ease: Power4.easeOut },0)
  //.to($topline,0.3, {y:10, ease: Power4.easeOut, width: 30},0) 
  //.to($middleline,0.3, { x:50, autoAlpha: 0, ease: Power4.easeOut,},0) 
 // .to($bottomline,0.3, {y:-10, ease: Power4.easeOut, width: 30 },0) 
 // .to($topline, 0.4,  {rotation:45, transformOrigin:"50% 50%"}, 0.3)
//.to($bottomline, 0.4,  {rotation:-45, transformOrigin:"50% 50%"},0.3)
 //.set($menubutton, {className:"+=activemenuclass menu-icon menubutton a"},0) 
//.set($logo, {className:"+=logo arrive menunow"},0) 

  ;

  $(".activemenuclass").on("click", function() {
      
    tls.restart();
})







  $(".a.activemenuclass").on("click", function() {
  
   tls.kill();



})

  

  $(".menubutton").click(function(){
    $(this).toggleClass('activemenuclass');
    $("#cnavigationmodal").toggleClass("intro");
    $("#Laag_1").toggleClass("round");
    $("#logo").toggleClass("menunow");
    $(".poly").toggleClass("yes");
    $(".bot").toggleClass("on");
  });

  $(".button").click(function(){
    $("#cnavigationmodal").toggleClass("intro");
    $("#Laag_1").toggleClass("round");
    $("#logo").toggleClass("menunow");
    $(".menubutton").toggleClass("activemenuclass");
    $(".poly").toggleClass("yes");
    $(".bot").toggleClass("on");
  });


});






/*
(function() {

  function create() {
    var mainContainer = document.createElement("div");
    mainContainer.id = "mainContainer";
document.getElementById("navwrapper").append(mainContainer);

    for (var i = 0; i < 2000; i++) {
      var divBlock = document.createElement("div");
      var divcircle = document.createElement("div");
      divBlock.className = "blocks";
      divcircle.className = "circles";
      mainContainer.appendChild(divBlock);
      divBlock.appendChild(divcircle);
    }

  }

  create();


}());
/*
var moveForce = 90; // max popup movement in pixels
var rotateForce = 60; // max popup rotation in deg

$(document).mousemove(function(e) {
    var docX = $(document).width();
    var docY = $(document).height();
    
    var moveX = (e.pageX - docX/2) / (docX/2) * -moveForce;
    var moveY = (e.pageY - docY/2) / (docY/2) * -moveForce;
    
    var rotateY = (e.pageX / docX * rotateForce*2) - rotateForce;
    var rotateX = -((e.pageY / docY * rotateForce*2) - rotateForce);
    
    $('.blocks')
        .css('left', moveX+'px')
        .css('top', moveY+'px')
        .css('transform', 'rotateX('+rotateX+'deg) rotateY('+rotateY+'deg)');
});

-webkit-overflow-scrolling: touch;
var $mouseX;
var $mouseY;
var $scrolled;


$('.boxes').on(' mousemove mouseenter',function(e){
	$mouseX = e.pageX||e.clientX+document.body.scrollLeft;
	$mouseY = e.pageY||e.clientY+document.body.scrollTop;
	$scrolled = $(window).scrollTop();
	
	var $elemLeft = $mouseX - $(this).offset().left;
	var $elemTop = ($mouseY - $scrolled) - ( $(this).offset().top - $scrolled);
  $(this).children('.circle').css({'transition':'all 0.0s ease-in-out','opacity':1,'width':'10%','height':'10%','left':$elemLeft , 'top':$elemTop})
  
});
$('.boxes').on('mouseout mouseleave',function(){
  $(this).children('.circle').css({'transition':'all 0.5s ease-in-out','left':'43%', 'top':'43%','width':'6%','height':'6%','opacity':0.5})
});



document.addEventListener('DOMContentLoaded', function () {
  var dropdownItem = document.querySelectorAll('.button span');
  var dropdown = document.querySelector('.button span');
  

  for (let i = 0; i < dropdownItem.length; i++) {
    dropdownItem[i].addEventListener("mouseenter", function(event) {
      var mx = (this.id);
      if (mx === "Home"){
        document.getElementById("myspan").textContent= "HOME" + "\n" + "WIJ ZIJN EEN " + "\n" + "ROTTEDAMS DIGITAAL" + "\n" +"BUREAU";
        document.getElementById("myspan1").textContent= "HOME" + "\n" + "WIJ ZIJN EEN " + "\n" + "ROTTEDAMS DIGITAAL" + "\n" +"BUREAU";}
      if (mx === "cases"){
        document.getElementById("myspan").textContent= "CASES" + "\n" + "WIJ ZIJN EEN " + "\n" + "ROTTEDAMS DIGITAAL" + "\n" +"BUREAU";
        document.getElementById("myspan1").textContent= "CASES" + "\n" + "WIJ ZIJN EEN " + "\n" + "ROTTEDAMS DIGITAAL" + "\n" +"BUREAU";
      }
    });

    dropdownItem[i].addEventListener("mouseleave", function(event) {
      var mx = (this.id);
      document.getElementById("myspan").textContent= " ";
      document.getElementById("myspan1").textContent= " ";
  
    });
}
})




document.addEventListener('DOMContentLoaded', function () {
  var dropdownItem = document.querySelector('.button');
  var dropdown = document.querySelector('.button span');
  var dropdowns = document.querySelector('.meme');

  dropdownItem.addEventListener('mouseenter', function (event) {
   // var mx = event.target.id;
      dropdown.classList.add('dropdown-show');
      dropdowns.classList.add('oja');
     //  document.getElementById("myspan").textContent="newtext";
     //  document.getElementById("myspan1").textContent="newtext";
  });
  dropdownItem.addEventListener('mouseleave', function () {
   //  document.getElementById("myspan").textContent=" ";
   // document.getElementById("myspan1").textContent=" ";
  })

})
*/
document.addEventListener('DOMContentLoaded', function () {
  var dropdownItems = document.querySelector('.Home');
  var dropdowns = document.querySelector('.Home span');

  dropdownItems.addEventListener('mouseenter', function (event) {
   // var mx = event.target.id;
      dropdowns.classList.add('oja');
     //  document.getElementById("myspan").textContent="newtext";
     //  document.getElementById("myspan1").textContent="newtext";
  });
  dropdownItems.addEventListener('mouseleave', function () {
   //  document.getElementById("myspan").textContent=" ";
   // document.getElementById("myspan1").textContent=" ";
  })

})
/*
$(document).ready(function(){

var ratio = 0.09; //delay follow cursor
var active = false;
var bee = document.getElementById("meme");
document.addEventListener("mousemove", getMouse); 


bee.style.position = "absolute"; //css		
var beepos = {x:0, y:0};

setInterval(followMouse, 50);

var mouse = {x:0, y:0}; //mouse.x, mouse.y

var dir = "right";
function getMouse(e){
  mouse.x = e.pageX;
  mouse.y = e.pageY;
    //Checking directional change
    if(mouse.x > beepos.x){
      dir = "right";
    } else {
      dir = "left";
    }
}

function followMouse(){
  //1. find distance X , distance Y
  var distX = mouse.x - beepos.x;
  var distY = mouse.y - beepos.y;
  //Easing motion
   //Progressive reduction of distance 
  beepos.x += distX/5;
  beepos.y += distY/2;
  
  bee.style.left = beepos.x + "px";
  bee.style.top = beepos.y + "px";
  
  
    //Apply css class 
    if (dir == "right"){
      bee.setAttribute("class", "right");
    } else {
      bee.setAttribute("class", "left");        
    }
  
}
});
*/
 

    $('#about').click(function(){
      var tlv = new TimelineMax({
        onComplete: goToNextPages,
       yoyo: false,
       force3D:false
       });
       gsap.fromTo('#meme.right',1.5,{ scale:1, force3D:true}, {delay: 0, force3D:true, scale:600, ease:ExpoScaleEase.config(0.1,600, Power2.easeOut) });  
       gsap.fromTo('#meme.left',1.5,{ scale:1, force3D:true}, {delay: 0, force3D:true, scale:600, ease:ExpoScaleEase.config(0.1,600, Power2.easeOut) });  
       tlv.fromTo('.no',1.5,{ scale: "1"}, {delay: 0.5,  scale: "200" });            
    });

    function goToNextPages(){
      window.location.replace('/about');
  }

  $('#home').click(function(){
    var tlv = new TimelineMax({
      onComplete: goToNextPage,
     yoyo: false,
     force3D:false
     });
     gsap.fromTo('#meme.right',1.5,{ scale:1, force3D:true}, {delay: 0, force3D:true, scale:600, ease:ExpoScaleEase.config(0.1,600, Power2.easeOut) });  
     gsap.fromTo('#meme.left',1.5,{ scale:1, force3D:true}, {delay: 0, force3D:true, scale:600, ease:ExpoScaleEase.config(0.1,600, Power2.easeOut) });  
   tlv.fromTo('.no',1.5,{ scale: "1"}, {delay: 0.5,  scale: "200" });        

 
  });

  function goToNextPage(){
    window.location.replace('/basehome');
}

$('#intro').click(function(){
  var tlv = new TimelineMax({
    onComplete: goToNextPagec,
   yoyo: false,
   force3D:true
   });
 
   gsap.fromTo('#meme.right',1.5,{ visibility: 'hidden', scale:1, force3D:true}, {delay: 0, force3D:true,visibility: 'visible', scale:600, ease:ExpoScaleEase.config(0.1,600, Power2.easeOut) });  
 gsap.fromTo('#meme.left',1.5,{  visibility: 'hidden',scale:1, force3D:true}, {delay: 0, force3D:true, visibility: 'visible', scale:600, ease:ExpoScaleEase.config(0.1,600, Power2.easeOut) });  
 tlv.fromTo('.no',1.5,{ scale: "1"}, {delay: 0.5,  scale: "200" });       


});

function goToNextPagec(){
  window.location.replace('/home');
}


$(function(){  

  var wrapper = $("#wrapper"),
      $menus = $("#topbar"),
      $window = $(window);

  
  $menus.on("click",".Home", function(){
      var $this = $(this),
          href = $this.attr("href"),
          topY = $(href).offset().top;
     
      gsap.to($window, 1, {
          scrollTo:{
              y: topY
            //  autoKill: true
          }, 
          ease:Power3.easeOut 
       });

      
    return false;
  }); 
  
  


  
    
  });
 
  

}
}








   







		
 
