
<main>

<div id="topbar">
  <div class="topbar">
    <div class="row">
      <div class="hold1">
    <div id="logo" class="logo arrive">
      <a class="" [routerLink]="['']" routerLinkActive ="is-active"> <svg version="1.1" id="Laag_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
         viewBox="0 0 68 88" style="enable-background:new 0 0 68 88;" xml:space="preserve">
      <g>
        <path class="st10" d="M53.5,85.4L43.2,67.5c-4.2-6.8-6.8-11.3-9.4-16h-0.2c-2.3,4.7-4.6,9-8.8,16.1l-9.7,17.8h-12l24.7-40.9L4.1,4.5h12.1
          l10.7,19c3,5.3,5.3,9.4,7.4,13.7h0.4c2.3-4.8,4.3-8.5,7.3-13.7l11-19h12L40.4,43.9l25.2,41.5H53.5z"/>
      </g>
      </svg></a>
    </div>
  </div>

    <div class="containerb">

      <div class="headermenu">
     <!-- <div class="contact-area"><a class="buttons Home"  routerLinkActive ="is-active"><span id="home">home</span></a></div>  -->
     <div class="contact-area"><a href="#case" class="buttons cases Home"  routerLinkActive ="is-active" ><span id="cases">cases</span></a></div>
      <div class="contact-area"><a href="#overons" class="buttons overons Home"  routerLinkActive ="is-active"><span id="aboutus">over ons</span></a></div>
      <div class="contact-area"><a href="#contact" class="buttons contact Home"  routerLinkActive ="is-active"><span id="contactus">contact</span></a></div>
 <!-- <div class="contact-area intro"><a class="buttons Home intro"  routerLinkActive ="is-active"><span id="intro">intro</span></a></div> -->
      <div id="pause" class="menu-icon menubutton a">
    <span id="line-1" class="line-1"></span>
    <span class="line-2"></span>
    <span class="line-3"></span>
  </div>
  
</div>
</div>
</div>
</div>
</div>



<div id="menuwindow">
<div class="menuwindow">
</div>
</div>

<div class="bottomdiv"></div>
<!--
<nav id="cnavigationmodal" class="the">
  <div class="poly"></div>
  <div id="navwrapper">
  <div class="navwarp">
  <a class="button Home" [routerLink]="['/basehome']" routerLinkActive ="is-active"><span id="Home">Home</span></a>
  <a  class="button cases"><span id="cases">Cases</span></a>
  <a class="button about" [routerLink]="['/about']" routerLinkActive="is-active"><span id="about">About</span></a>
  <a class="button contact" ><span>Contact</span></a>
  <a class="button intro" [routerLink]="['/home']" routerLinkActive="is-active"><span>Intro</span></a>
</div>
</div>
<div ></div>

<div class="bot">
  <div class="inner">
    <span id="myspan"></span> 
  </div>
  <div class="inner">
    <span id="myspan1"></span>
  </div>
</div> 
  </nav> -->

  


<router-outlet></router-outlet>

<div id="cookie-consent-banner"
[ngClass]="status ? 'noshowcookie' : 'showcookie'">
  <div class="cookie-consent-banner__inner">
    <div class="cookie-consent-banner__copy">
      <div class="cookie-consent-banner__header">Wij maken gebruik van cookies</div>
      <div class="cookie-consent-banner__description">Wij maken gebruik van cookies om ervoor te zorgen dat onze website zo soepel mogelijk draait. Wij gebruiken cookies voor het bijhouden van statistieken (de cookies van google Analytics zijn volledig geanonimiseerd). Druk op ok als je wilt doorgaan met het gebruiken van de website.</div>
    </div>

    <div class="cookie-consent-banner__actions">
      <p class="cookie-consent-banner__cta"(click)="status=!status">
        OK
      </p> 

    <!--    <p class="cookie-consent-banner__cta cookie-consent-banner__cta--secondary" >
    
        NIET OK
      </p> -->

    </div>
  </div>
</div>