<main>

    <div id="box5" class="box4 active"  >


<ul>  
  <li><p><strong>Project</strong></p>
    <p>Een website voor IABR. Dit webmagazine biedt reflectie en perspectieven op de toekomst van de stad in The Next Economy. Het is een uitbreiding op het programma WHAT’S NEXT? dat vond plaats tijdens de 7e editie van de Internationale Architectuur Biënnale Rotterdam, IABR ‒ 2016 ‒ THE NEXT ECONOMY, van 23 april t / m 10 juli 2016. </p></li>
  <li><p><strong>Klant</strong></p>
    <p>Internationale Architectuur Biennale Rotterdam</p></li>

<li><p><strong>Design</strong></p>
<p>75B</p></li>

<li><p><strong>Development</strong></p>
  <p>Xwebdevelopment</p>  </li>

  <li><p><strong>Url</strong></p>
    <p><a href="https://next.iabr.nl" target="_blank">https://next.iabr.nl</a></p>  </li>
    <li>
    </li>
    <li><p><strong>Visuele impressies</strong></p>
    </li>
</ul>

<img  class="images" src="./assets/img/nextiabr1.jpg"/>
<video width="100%" autoplay="autoplay" onloadedmetadata="this.muted = true"  loop playsinline >
    <source src="./assets/img/whnext.mp4" type="video/mp4">
  </video>
<img  class="images" src="./assets/img/nextiabr2.jpg"/>
<img  class="images" src="./assets/img/nextibar4.jpg"/>


</div></main>



