<main>

    <div id="box5" class="box4 active"  >


<ul>  
  <li><p><strong>Project</strong></p>
    <p>Een website voor een stedenbouwkundig bureau op het gebied van ruimtelijke ordening.</p></li>
  <li><p><strong>Klant</strong></p>
    <p>Voids urbanism</p></li>

<li><p><strong>Design</strong></p>
<p>Thijs de boer & Johan van ling</p></li>

<li><p><strong>Development</strong></p>
  <p>Xwebdevelopment</p>  </li>
        <li><p><strong>Url</strong></p>
            <p><a href="https://voidsurbanism.eu" target="_blank">https://voidsurbanism.eu</a></p>  </li>
    <li><p><strong>Visuele impressies</strong></p>
    </li>
</ul>

<img  class="images" src="./assets/img/voidsurbanism.jpg"/>
<img  class="images" src="./assets/img/work.jpg"/>
<img  class="images" src="./assets/img/singlework.jpg"/>
<img  class="images" src="./assets/img/about.jpg"/>
<img  class="images" src="./assets/img/aboutthat.jpg"/>

</div></main>



