import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutComponent } from './about/about.component';

import { RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { BasehomeComponent } from './basehome/basehome.component';

import { NgCircleProgressModule } from 'ng-circle-progress';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgImageAsParticlesModule } from 'ng-image-as-particles';
import { BlancgoComponent } from './blancgo/blancgo.component';
import { EnforzComponent } from './enforz/enforz.component';
import { BuurtcirkelComponent } from './buurtcirkel/buurtcirkel.component';
import { WinetankComponent } from './winetank/winetank.component';
import { MosterdencoComponent } from './mosterdenco/mosterdenco.component';
import { VoordewijkComponent } from './voordewijk/voordewijk.component';
import { GroosmanComponent } from './groosman/groosman.component';
import { IabrComponent } from './iabr/iabr.component';
import { GrootzusComponent } from './grootzus/grootzus.component';
import { VfiComponent } from './vfi/vfi.component';
import { VoidsurbanismComponent } from './voidsurbanism/voidsurbanism.component';



@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    HomeComponent,
    BasehomeComponent,
    BlancgoComponent,
    EnforzComponent,
    BuurtcirkelComponent,
    WinetankComponent,
    MosterdencoComponent,
    VoordewijkComponent,
    GroosmanComponent,
    IabrComponent,
    GrootzusComponent,
    VfiComponent,
    VoidsurbanismComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SlickCarouselModule,
    NgImageAsParticlesModule,
    RouterModule.forRoot([
    { path: 'about', component: AboutComponent },
    { path: 'blancgo', component: BlancgoComponent },
    { path: 'home', component: BasehomeComponent},
    { path: '', component: HomeComponent },
    { path: '**', redirectTo: '' },
], { 
  relativeLinkResolution: 'legacy' }),

    




    
    NgCircleProgressModule.forRoot({
      "radius": 60,
      "space": -5,
      "outerStrokeWidth": 5,
      "outerStrokeColor": "#76C2AF",
      "innerStrokeColor": "#ffffff",
      "innerStrokeWidth": 5,
      "imageSrc": "assets/img/thinarrow-25.png",
      "imageHeight": 55,
      "imageWidth": 55,
      "showImage": true,
      'class': 'circlearrow',
      "showBackground": false})
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
