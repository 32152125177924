import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  constructor() {
    $( document ).ready(function() {   
var newUrl = 'basehome/#part2' ;
    $('.cases').attr('href', newUrl);

    });
   }

  ngOnInit(): void {

  }

}
