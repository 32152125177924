<main>

    <div id="box5" class="box4 active"  >


<ul>  
  <li><p><strong>Project</strong></p>
    <p>Een website en registratie systeem voor VFI register. Het VFI register heeft als doel de kwaliteit en vakbekwaamheid van Veilig Fysiek Ingrijpen te waarborgen en waar mogelijk te verbeteren. Tevens streeft het VFI Register naar meer éénduid in de diverse methodieken die aangeboden worden.</p></li>
  <li><p><strong>Klant</strong></p>
    <p>VFI rgegister</p></li>

<li><p><strong>Design</strong></p>
<p>Xwebdevelopment</p></li>

<li><p><strong>Illustratie</strong></p>
    <p>Stéphane Hartog</p>  </li>

<li><p><strong>Development</strong></p>
  <p>Xwebdevelopment</p>  </li>

  <li><p><strong>Url</strong></p>
    <p><a href="https://vfiregister.nl" target="_blank">Https://vfiregister.nl</a></p>  </li>
    <li>
    </li>
    <li><p><strong>Visuele impressies</strong></p>
    </li>
</ul>

<img  class="images" src="./assets/img/vfi.jpg"/>
<img  class="images" src="./assets/img/vfi2.jpg"/>
<img  class="images" src="./assets/img/vfi11.jpg"/>
<img  class="images" src="./assets/img/vfi1.jpg"/>


</div></main>


