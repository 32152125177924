import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-voidsurbanism',
  templateUrl: './voidsurbanism.component.html',
  styleUrls: ['./voidsurbanism.component.css']
})
export class VoidsurbanismComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
