import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-voordewijk',
  templateUrl: './voordewijk.component.html',
  styleUrls: ['./voordewijk.component.css']
})
export class VoordewijkComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
