import { Component, OnInit, } from '@angular/core';
import { TweenLite, TimelineMax, TimelineLite, Back, Power4,} from 'gsap'; 




@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor() { }

    public ngOnInit()
    {



      $(document).ready(function(){
        var pauseBtn = document.getElementById("pause"),
    tls = new TimelineMax({
     onUpdate: updateProgress, 
      onComplete:someFunction,
    yoyo: false,
    force3D:false
    });
      
    let toCatalog = gsap.timeline({paused: true});



 
    gsap.set($("#svgwrap"), {delay: 0,visibility:"visible"}); //change color tekst 
    gsap.set($("#together"), {delay: 3,visibility:"visible"}); //change color tekst 
    gsap.set($("#logo"), {delay: 0,visibility:"hidden"}); //change color tekst 
    gsap.set($(".menu-icon"), {delay: 0,visibility:"hidden"}); //change color tekst 
    gsap.set($("#topbar"), {delay: 0,visibility:"hidden"}); //change color tekst 
    gsap.set($("#meme"), {delay: 0,visibility:"hidden"}); //change color tekst 
    gsap.set($("body"), {delay: 0,backgroundColor:"#000000", position:"fixed", width:"100%", height:"100%"}); //change color tekst #222121
    gsap.to("#brandbox", {delay: 0.8, opacity:0.2});// xwebdevelopment
    gsap.to($("body"), {delay: 1.5,backgroundColor:"#222121"}); //change color tekst #222121
    gsap.to(".wrap2", {rotationY: -720, duration: 1.5, delay:0.5, ease: Power0.easeInOut});
    gsap.to(".wrap3", {rotationY: -720, duration: 1.5, delay:0.6, ease: Power0.easeInOut});
    gsap.to(".wrap4", {rotationY: -720, duration: 1.5, delay:0.7, ease: Power0.easeInOut});
    gsap.to(".wrap5", {rotationY: -720, duration: 1.5, delay:0.8, ease: Power0.easeInOut});
    gsap.to(".wrap6", {rotationY: -720, duration: 1.5, delay:0.9, ease: Power0.easeInOut});
    gsap.to(".wrap7", {rotationY: -720, duration: 1.5, delay:1, ease: Power0.easeInOut});
    gsap.to(".wrap8", {rotationY: -720, duration: 1.5, delay:1.1, ease: Power0.easeInOut});
    gsap.to(".wrap9", {rotationY: -720, duration: 1.5, delay:1.2, ease: Power0.easeInOut});
    gsap.to(".wrap10", {rotationY: -720, duration: 1.5, delay:1.3, ease: Power0.easeInOut});
    gsap.to(".wrap11", {rotationY: -720, duration: 1.5, delay:1.4, ease: Power0.easeInOut});
    gsap.to(".wrap12", {rotationY: -720, duration: 1.5, delay:1.5, ease: Power0.easeInOut});
    gsap.to(".wrap13", {rotationY: -720, duration: 1.5, delay:1.6, ease: Power0.easeInOut});
    gsap.to(".wrap14", {rotationY: -720, duration: 1.5, delay:1.7, ease: Power0.easeInOut});
    gsap.to(".wrap15", {rotationY: -720, duration: 1.5, delay:1.8, ease: Power0.easeInOut});
    gsap.set("body", {delay: 1, overflow:"hidden"});// overlay white black open
    gsap.set("#svgwrap", {delay: 3.5, duration: 3, opacity:0});// xwebdevelopment
    gsap.set("#box", {opacity:0, translateY:80, skewY:-2.5});// tekst white/black open
    gsap.set("#box1", {opacity:0, translateY:80, skewY:-2.5});// tekst white/black open
    gsap.set("#box2", {opacity:0, translateY:80, skewY:-2.5});// tekst white/black open
    gsap.set("#box3", {opacity:0, translateY:80, skewY:-2.5});// tekst white/black open
    gsap.to("#box",0.5, {delay: 4.8, opacity:1, translateY:0, skewY:0});// tekst white/black open

   // gsap.to('#momo',1,{ delay: 6.4,  visibility: 'visible', force3D:true,yoyo:true, repeat: 1, scale:1,  ease:ExpoScaleEase.config(0.1,1, Power2.easeOut) });  

    //gsap.from(".cover", 0.8, {delay: 6.6,  scaleX: 0, transformOrigin: "left" });
   // gsap.to(".cover", 0.5, {delay: 7.4,  scaleX: 0, transformOrigin: "right" });
    gsap.set(".cover",{ opacity:"0" });
    gsap.to(".cover", 0.8, {delay: 6.6,  opacity:"1" });
    gsap.to(".cover", 0.5, {delay: 7.4,  opacity:"0" });


    




   // chane gsap.to("#overlayme", {delay: 6.4, width:"100%"});// overlay white black open
    gsap.to("#brandbox", {delay: 6.6, opacity:0});// xwebdevelopment
    gsap.to("#brandbox1", {delay: 6.7, opacity:0.2});// xwebdevelopmentt

    gsap.to("#box", {delay: 7.2, translateX:1200, skewY:0 , opacity:0, }); // tekst white/black close
   // chane  gsap.set("#overlayme", {delay: 7.4, width:"0%", right:'unset'});// overlay white black close
  // gsap.to('#momo',1.5,{delay: 7.4, force3D:true, scale:1, ease:ExpoScaleEase.config(0.1,600, Power2.easeOut) }); 
  // gsap.to('#momo',{delay:8.9, visibility: 'hidden' });
    gsap.fromTo('#brandbox1',5,{scale: 1.3}, {delay: 7.3, scale: 1});
    gsap.set($(".colorme"), {delay: 7.6,color:"#FDC4D9"}); //change color tekst
    gsap.to("#box1", {delay: 8.6, opacity:1, translateY:0, skewY:0}); // tekst dog open
   // gsap.to('#momo',1,{ delay: 10.7,  visibility: 'visible', force3D:true,yoyo:true, repeat: 1, scale:1, ease:ExpoScaleEase.config(0.1,1, Power2.easeOut) }); 
    
    //gsap.from(".cover1", 0.5, {delay: 11.3,  scaleX: 0, transformOrigin: "right" });
    // gsap.to(".cover1", 0.5, {delay: 11.8,  scaleX: 0, transformOrigin: "left" });
    gsap.set(".cover1",{ opacity:"0" });
    gsap.to(".cover1", 0.8, {delay: 11.3,  opacity:"1" });
    gsap.to(".cover1", 0.5, {delay: 12.1,  opacity:"0" });
    
    // chane gsap.to("#overlayme1", {delay: 9.7, width:"100%"});// overlay dog open
    gsap.to("#brandbox1", {delay: 11, opacity:0});// xwebdevelopment
    gsap.to("#brandbox2", {delay: 11, opacity:0.2});// xwebdevelopment
    gsap.fromTo('#brandbox2',6,{scale: 1.3}, {delay: 11, scale: 1});
    gsap.to("#box1", {delay: 11.8, translateX:-1200, skewY:0 , opacity:0});//tekst dog close
   // chane  gsap.set("#overlayme1", {delay: 10.7, width:"0%" ,left:'unset', right:0});// overlay dog close
    gsap.set($(".colorme"), {delay: 12,color:"#4ec5a5"}); //change color tekst
    
    
    
    gsap.to("#box2", {delay: 12.8, opacity:1, translateY:0, skewY:0}); //tekst peolple open 
     //gsap.to('#momo',1,{ delay: 14.9,  visibility: 'visible', force3D:true,yoyo:true, repeat: 1, scale:1, ease:ExpoScaleEase.config(0.1,1, Power2.easeOut) });  
     //gsap.from(".cover2", 0.5, {delay: 15.5,  scaleX: 0, transformOrigin: "left" });
     //gsap.to(".cover2", 0.5, {delay: 16,  scaleX: 0, transformOrigin: "right" });

     gsap.set(".cover2",{ opacity:"0" });
     gsap.to(".cover2", 0.8, {delay: 15.5,  opacity:"1" });
     gsap.to(".cover2", 0.5, {delay: 16,  opacity:"0" });
     
      
       // chane gsap.to("#overlayme2", {delay: 12.9, width:"100%"});// overlay dog open
     gsap.to("#brandbox2", {delay: 15.2, opacity:0});// xwebdevelopment
    gsap.to("#brandbox3", {delay: 15.2, opacity:0.2});// xwebdevelopment
    gsap.fromTo('#brandbox3',6,{scale: 1.3}, {delay: 15.2, scale: 1});
    gsap.set("#box2", {delay: 15.8, translateX:1200, skewY:0 , opacity:0}); //tekst peolple open 
   // chane  gsap.set("#overlayme2", {delay: 13.9, width:"0%", right:"unset"});// overlay dog close
    gsap.set($(".colorme"), {delay:15.9,color:"#d13b40"}); //change color tekst
    

    
   /* gsap.set("#containerp", { borderRadius:'50%', translateX:'700%', translateY:'50%'}); //tekst peolple open 
    gsap.set("#containerm", { borderRadius:'50%',translateX:'700%', translateY:'50%'}); //tekst peolple open 
    gsap.set("#containera", { borderRadius:'50%',translateX:'700%', translateY:'50%'}); //tekst peolple open 
    gsap.set("#containerb", { borderRadius:'50%',translateX:'700%', translateY:'50%'}); //tekst peolple open 
    gsap.set("#containerc", { borderRadius:'50%',translateX:'700%', translateY:'50%'}); //tekst peolple open 
    gsap.set("#containerd", { borderRadius:'50%',translateX:'700%', translateY:'50%'}); //tekst peolple open 
    gsap.set("#containere", { borderRadius:'50%',translateX:'700%', translateY:'50%'}); //tekst peolple open 
    gsap.set("#containerf", { borderRadius:'50%',translateX:'700%', translateY:'50%'}); //tekst peolple open 
    gsap.set("#containerg", { borderRadius:'50%',translateX:'700%', translateY:'50%'}); //tekst peolple open 
    
     if ($(window).width() > 1370 ) {
    gsap.to("#containerp", {delay: 17.4, translateX:'20%', translateY:'70%', scale: 0.1}); //tekst peolple open 
    gsap.to("#containerm", {delay: 17.5, translateX:'7%', translateY:'70%', scale: 0.1}); //tekst peolple open 
    gsap.to("#containera", {delay: 17.6, translateX:'33%', translateY:'70%', scale: 0.1}); //tekst peolple open 
    gsap.to("#containerb", {delay: 17.7, translateX:'33%', translateY:'83%', scale: 0.1}); //tekst peolple open 
    gsap.to("#containerc", {delay: 17.8, translateX:'33%', translateY:'96%', scale: 0.1}); //tekst peolple open 
    gsap.to("#containerd", {delay: 17.9, translateX:'20%', translateY:'83%', scale: 0.1}); //tekst peolple open 
    gsap.to("#containere", {delay: 18, translateX:'20%', translateY:'96%', scale: 0.1}); //tekst peolple open 
    gsap.to("#containerf", {delay: 18.1, translateX:'7%', translateY:'83%', scale: 0.1}); //tekst peolple open 
    gsap.to("#containerg", {delay: 18.2, translateX:'7%', translateY:'96%', scale: 0.1}); //tekst peolple open 

    gsap.to("#containerp",2, {delay:19, borderRadius:'0%', translateX:'20%', translateY:'70%', scale: 0.1}); //tekst peolple open 
    gsap.to("#containerm",2, {delay:19, borderRadius:'0%', translateX:'7%', translateY:'70%', scale: 0.1}); //tekst peolple open 
    gsap.to("#containera",2, {delay:19, borderRadius:'0%', translateX:'33%', translateY:'70%', scale: 0.1}); //tekst peolple open 
    gsap.to("#containerb",2, {delay:19, borderRadius:'0%', translateX:'33%', translateY:'83%', scale: 0.1}); //tekst peolple open 
    gsap.to("#containerc",2, {delay:19, borderRadius:'0%', translateX:'33%', translateY:'96%', scale: 0.1}); //tekst peolple open 
    gsap.to("#containerd",2, {delay:19, borderRadius:'0%', translateX:'20%', translateY:'83%', scale: 0.1}); //tekst peolple open 
    gsap.to("#containere",2, {delay:19, borderRadius:'0%', translateX:'20%', translateY:'96%', scale: 0.1}); //tekst peolple open 
    gsap.to("#containerf",2, {delay:19, borderRadius:'0%', translateX:'7%', translateY:'83%', scale: 0.1}); //tekst peolple open 
    gsap.to("#containerg",2, {delay:19, borderRadius:'0%', translateX:'7%', translateY:'96%', scale: 0.1}); //tekst peolple open 
/*
    gsap.to("#containerp",2, {delay:21, translateX:'20%', translateY:'70%', scale: 0.2}); //tekst peolple open 
    gsap.to("#containerm",2, {delay:21, translateX:'7%', translateY:'70%', scale: 0.2}); //tekst peolple open 
    gsap.to("#containera",2, {delay:21, translateX:'33%', translateY:'70%', scale: 0.2}); //tekst peolple open 
    gsap.to("#containerb",2, {delay:21, translateX:'33%', translateY:'83%', scale: 0.2}); //tekst peolple open 
    gsap.to("#containerc",2, {delay:21, translateX:'33%', translateY:'96%', scale: 0.2}); //tekst peolple open 
    gsap.to("#containerd",2, {delay:21, translateX:'20%', translateY:'83%', scale: 0.2}); //tekst peolple open 
    gsap.to("#containere",2, {delay:21, translateX:'20%', translateY:'96%', scale: 0.2}); //tekst peolple open 
    gsap.to("#containerf",2, {delay:21, translateX:'7%', translateY:'83%', scale: 0.2}); //tekst peolple open 
    gsap.to("#containerg",2, {delay:21, translateX:'7%', translateY:'96%', scale: 0.2}); //tekst peolple open 
 
    gsap.set(".heart", {display:"block",translateX:'188%', translateY:620, rotate:'-45deg', scale:0}); //tekst peolple open 
     }

    if ($(window).width() < 1370 ) {

      gsap.to("#containerp", {delay: 16.4, translateX:'-20%', translateY:'17%', scale: 0.07}); //tekst peolple open 
      gsap.to("#containerm", {delay: 16.5, translateX:'-10%', translateY:'17%', scale: 0.07}); //tekst peolple open 
      gsap.to("#containera", {delay: 16.6, translateX:'0%', translateY:'17%', scale: 0.07}); //tekst peolple open 
      gsap.to("#containerb", {delay: 16.7, translateX:'-20%', translateY:'29%', scale: 0.07}); //tekst peolple open 
      gsap.to("#containerc", {delay: 16.8, translateX:'-20%', translateY:'41%', scale: 0.07}); //tekst peolple open 
      gsap.to("#containerd", {delay: 16.9, translateX:'-10%', translateY:'29%', scale: 0.07}); //tekst peolple open 
      gsap.to("#containere", {delay: 17, translateX:'-10%', translateY:'41%', scale: 0.07}); //tekst peolple open 
      gsap.to("#containerf", {delay: 17.1, translateX:'0%', translateY:'29%', scale: 0.07}); //tekst peolple open 
      gsap.to("#containerg", {delay: 17.2, translateX:'0%', translateY:'41%', scale: 0.07}); //tekst peolple open 
  
      gsap.to("#containerp",2, {delay:19, borderRadius:'0%', translateX:'-20%', translateY:'17%', scale: 0.07}); //tekst peolple open 
      gsap.to("#containerm",2, {delay:19, borderRadius:'0%', translateX:'-10%', translateY:'17%', scale: 0.07}); //tekst peolple open 
      gsap.to("#containera",2, {delay:19, borderRadius:'0%', translateX:'0%', translateY:'17%', scale: 0.07}); //tekst peolple open 
      gsap.to("#containerb",2, {delay:19, borderRadius:'0%', translateX:'-20%', translateY:'29%', scale: 0.07}); //tekst peolple open 
      gsap.to("#containerc",2, {delay:19, borderRadius:'0%', translateX:'-20%', translateY:'41%', scale: 0.07}); //tekst peolple open 
      gsap.to("#containerd",2, {delay:19, borderRadius:'0%', translateX:'-10%', translateY:'29%', scale: 0.07}); //tekst peolple open 
      gsap.to("#containere",2, {delay:19, borderRadius:'0%', translateX:'-10%', translateY:'41%', scale: 0.07}); //tekst peolple open 
      gsap.to("#containerf",2, {delay:19, borderRadius:'0%', translateX:'0%', translateY:'29%', scale: 0.07}); //tekst peolple open 
      gsap.to("#containerg",2, {delay:19, borderRadius:'0%', translateX:'0%', translateY:'41%', scale: 0.07}); //tekst peolple open 
    
   
    gsap.set(".heart", {display:"block",translateX:'82%', translateY:350, rotate:'-45deg', scale:0}); //tekst peolple open 
    
    }









    
    if ($(window).width() < 610) {

      gsap.to("#containerp", {delay: 16.4, translateX:'-26%', translateY:'17%', scale: 0.07}); //tekst peolple open 
      gsap.to("#containerm", {delay: 16.5, translateX:'-16%', translateY:'17%', scale: 0.07}); //tekst peolple open 
      gsap.to("#containera", {delay: 17.6, translateX:'-6%', translateY:'17%', scale: 0.07}); //tekst peolple open 
      gsap.to("#containerb", {delay: 16.7, translateX:'-26%', translateY:'29%', scale: 0.07}); //tekst peolple open 
      gsap.to("#containerc", {delay: 16.8, translateX:'-26%', translateY:'41%', scale: 0.07}); //tekst peolple open 
      gsap.to("#containerd", {delay: 16.9, translateX:'-16%', translateY:'29%', scale: 0.07}); //tekst peolple open 
      gsap.to("#containere", {delay: 17, translateX:'-16%', translateY:'41%', scale: 0.07}); //tekst peolple open 
      gsap.to("#containerf", {delay: 17.1, translateX:'-6%', translateY:'29%', scale: 0.07}); //tekst peolple open 
      gsap.to("#containerg", {delay: 17.2, translateX:'-6%', translateY:'41%', scale: 0.07}); //tekst peolple open 
  
      gsap.to("#containerp",2, {delay:19, borderRadius:'0%', translateX:'-26%', translateY:'17%', scale: 0.07}); //tekst peolple open 
      gsap.to("#containerm",2, {delay:19, borderRadius:'0%', translateX:'-16%', translateY:'17%', scale: 0.07}); //tekst peolple open 
      gsap.to("#containera",2, {delay:19, borderRadius:'0%', translateX:'-6%', translateY:'17%', scale: 0.07}); //tekst peolple open 
      gsap.to("#containerb",2, {delay:19, borderRadius:'0%', translateX:'-26%', translateY:'29%', scale: 0.07}); //tekst peolple open 
      gsap.to("#containerc",2, {delay:19, borderRadius:'0%', translateX:'-26%', translateY:'41%', scale: 0.07}); //tekst peolple open 
      gsap.to("#containerd",2, {delay:19, borderRadius:'0%', translateX:'-16%', translateY:'29%', scale: 0.07}); //tekst peolple open 
      gsap.to("#containere",2, {delay:19, borderRadius:'0%', translateX:'-16%', translateY:'41%', scale: 0.07}); //tekst peolple open 
      gsap.to("#containerf",2, {delay:19, borderRadius:'0%', translateX:'-6%', translateY:'29%', scale: 0.07}); //tekst peolple open 
      gsap.to("#containerg",2, {delay:19, borderRadius:'0%', translateX:'-6%', translateY:'41%', scale: 0.07}); //tekst peolple open 
    
   
    gsap.set(".heart", {display:"block",translateX:'62%', translateY:350, rotate:'-45deg', scale:0}); //tekst peolple open 
    
    }*/
 
    /*
    
    gsap.set("#test1", {delay: 15.6, clipPath:"ellipse(256px 256px at 800px 800px)"}); //tekst peolple open 
    gsap.set("#test2", {delay: 15.6, clipPath:"ellipse(256px 256px at 550px 550px)"}); //tekst peolple open 
    gsap.set("#test3", {delay: 15.6, clipPath:"ellipse(256px 256px at 375px 375px)"}); //tekst peolple open 
    
    gsap.set("#test11", {delay: 15.6, clipPath:"ellipse(256px 256px at 800px 800px)"}); //tekst peolple open 
    gsap.set("#test21", {delay: 15.6, clipPath:"ellipse(256px 256px at 550px 550px)"}); //tekst peolple open 
    gsap.set("#test31", {delay: 15.6, clipPath:"ellipse(256px 256px at 375px 375px)"}); //tekst peolple open 
    
    
    gsap.to("#test1", {delay: 16.6, clipPath:"ellipse(1000px 196px at 800px 735px)"}); //tekst peolple open 
    gsap.to("#test2", {delay: 16.6, clipPath:"ellipse(1000px 196px at 550px 485px)"}); //tekst peolple open 
    gsap.to("#test3", {delay: 16.6, clipPath:"ellipse(1000px 196px at 375px 310px)"}); //tekst peolple open 
    
    gsap.to("#test11", {delay: 16.6, clipPath:"ellipse(1000px 196px at 800px 735px)"}); //tekst peolple open 
    gsap.to("#test21", {delay: 16.6, clipPath:"ellipse(1000px 196px at 550px 485px)"}); //tekst peolple open 
    gsap.to("#test31", {delay: 16.6, clipPath:"ellipse(1000px 196px at 375px 310px)"}); //tekst peolple open 
    

   
    gsap.fromTo('#test1',0.5,{clipPath:"ellipse(256px 256px at 800px 800px)"}, {delay: 14, clipPath:"ellipse(1000px 196px at 800px 735px)"});
    gsap.fromTo('#test2',0.5,{clipPath:"ellipse(256px 256px at 550px 550px)"}, {delay: 14, clipPath:"ellipse(1000px 196px at 550px 485px)"});
    gsap.fromTo('#test3',0.5,{clipPath:"ellipse(256px 256px at 375px 375px)"}, {delay: 14, clipPath:"ellipse(1000px 196px at 375px 310px)"});
    
    
    gsap.fromTo('#test11',0.5,{clipPath:"ellipse(256px 256px at 800px 800px)"}, {delay: 14, clipPath:"ellipse(1000px 196px at 800px 735px)"});
    gsap.fromTo('#test21',0.5,{clipPath:"ellipse(256px 256px at 550px 550px)"}, {delay: 14, clipPath:"ellipse(1000px 196px at 550px 485px)"});
    gsap.fromTo('#test31',0.5,{clipPath:"ellipse(256px 256px at 375px 375px)"}, {delay: 14, clipPath:"ellipse(1000px 196px at 375px 310px)"});
  
     gsap.to("#containerp", {delay: 20.1, rotation: '+=360', repeat:5}); //tekst peolple open 
     gsap.to("#containerm", {delay: 20.1, rotation: '+=360', repeat:5}); //tekst peolple open 
     gsap.to("#containera", {delay: 20.1, rotation: '+=360', repeat:5}); //tekst peolple open 
     gsap.to("#containerb", {delay: 20.1, rotation: '+=360', repeat:5}); //tekst peolple open
     gsap.to("#containerc", {delay: 20.1, rotation: '+=360', repeat:5}); //tekst peolple open 
     gsap.to("#containerd", {delay: 20.1, rotation: '+=360', repeat:5}); //tekst peolple open
     gsap.to("#containere", {delay: 20.1, rotation: '+=360', repeat:5}); //tekst peolple open 
     gsap.to("#containerf", {delay: 20.1, rotation: '+=360', repeat:5}); //tekst peolple open
     gsap.to("#containerg", {delay: 20.1, rotation: '+=360', repeat:5}); //tekst peolple open 


    gsap.fromTo('#containerp',1,{scale: 0.2, transformOrigin: '50% 50%' }, {delay: 20.2, scale: 0});
    gsap.fromTo('#containerm',1,{scale: 0.2 , transformOrigin: '50% 50%' }, {delay: 20.2, scale: 0});
    gsap.fromTo('#containera',1,{scale: 0.2, transformOrigin: '50% 50%' }, {delay: 20.2, scale: 0});
    gsap.fromTo('#containerb',1,{scale: 0.2, transformOrigin: '50% 50%' }, {delay: 20.2, scale: 0});
    gsap.fromTo('#containerc',1,{scale: 0.2, transformOrigin: '50% 50%' }, {delay: 20.2, scale: 0});
    gsap.fromTo('#containerd',1,{scale: 0.2, transformOrigin: '50% 50%' }, {delay: 20.2, scale: 0});
    gsap.fromTo('#containere',1,{scale: 0.2, transformOrigin: '50% 50%' }, {delay: 20.2, scale: 0});
    gsap.fromTo('#containerf',1,{scale: 0.2, transformOrigin: '50% 50%' }, {delay: 20.2, scale: 0});
    gsap.fromTo('#containerg',1,{scale: 0.2, transformOrigin: '50% 50%' }, {delay: 20.2, scale: 0});

    
    gsap.fromTo('.heart',1,{scale: 0}, {delay: 20.7, scale: 1});
 
    
    if ($(window).width() < 610) {
      gsap.fromTo('#containerp',1,{scale: 0.07}, {delay: 20.2, scale: 0});
      gsap.fromTo('#containerm',1,{scale:0.07}, {delay: 20.2, scale: 0});
      gsap.fromTo('#containera',1,{scale:0.07}, {delay: 20.2, scale: 0});
      gsap.fromTo('#containerb',1,{scale:0.07}, {delay: 20.2, scale: 0});
      gsap.fromTo('#containerc',1,{scale:0.07}, {delay: 20.2, scale: 0});
      gsap.fromTo('#containerd',1,{scale:0.07}, {delay: 20.2, scale: 0});
      gsap.fromTo('#containere',1,{scale:0.07}, {delay: 20.2, scale: 0});
      gsap.fromTo('#containerf',1,{scale:0.07}, {delay: 20.2, scale: 0});
      gsap.fromTo('#containerg',1,{scale:0.07}, {delay: 20.2, scale: 0});
    
      gsap.fromTo('.heart',1,{scale: 0}, {delay: 20.7, scale: 0.8});
      
      }
        */
      gsap.to("#box3", {delay: 16.9, opacity:1, translateY:0, skewY:0}); //tekst peolple open  
    
    gsap.to("#brandbox3", {delay: 20, opacity:0});// xwebdevelopment
    //gsap.to('#momo',0.5,{ delay: 22.7,  visibility: 'visible', force3D:true, scale:1, ease:ExpoScaleEase.config(0.1,1, Power2.easeOut) });  

  
    gsap.from(".cover3", 0.5, {delay: 20.4,  scaleX: 0, transformOrigin: "right" });
    gsap.to(".cover3", 0.5, {delay: 20.9,  scaleX: 0, transformOrigin: "left" });
   // chane gsap.set("#overlayme3", {delay: 21.1, width:"100%"});// overlay dog open
     //gsap.to($("#together"), {delay: 22.2, translateX:1200});// overlay dog open 
    gsap.set($("body"), {delay: 20.9, backgroundColor:'#000'}); // foto peolple 
    //gsap.set("#svgwrap", {delay: 22.7, display:"none"}); //tekst peolple open 
   // chane  gsap.set("#overlayme3", {delay: 21.9, width:"0%", left:"unset", right:0});// overlay dog open
     gsap.set("#box3", {delay: 20.8, translateX:1200, skewY:0 , opacity:0}); //tekst peolple open 
     gsap.set($("#together"), {delay: 20.8, className:"+=close"});// overlay dog open 
    //gsap.to($("#topbar"), {delay: 22.9,visibility:"visible"}); //change color tekst 
   // gsap.to($("#logo"), {delay: 22,visibility:"visible"}); //change color tekst 
   // gsap.to($("#meme"), {delay: 22,visibility:"visible"}); //change color tekst 
   // gsap.set("#logo", {delay: 22, className:"+=arrive"}); //tekst peolple open 
    //gsap.set($(".colorme"), {delay: 23,color:"#000000"}); //change color tekst
    //gsap.to($(".menu-icon"), {delay: 22,visibility:"visible"}); //change color tekst 
    gsap.set($(".progresswrap"), {delay: 20,visibility:"hidden"}); //change color tekst 
    //gsap.to("#box4", {delay: 21.5, opacity:1, translateY:0, skewY:0}); //tekst peolple open  */
    tls.set("#overlayme4", {delay: 20.9, width:"0%"});// overlay dog open  
   // gsap.set("body", {delay: 23, overflow:"auto"});// overlay white black open 
  //  gsap.set($(".wrap1"), {delay: 33.5, rotationY:'+=-720', ease: Power0.easeInOut}); 
    pauseBtn.onclick = function() {
    tls.paused(!tls.paused());
    }
    
    
    /*
    function updateProgress(){
    TweenMax.set( $('#progress'), { value: tls.progress()*100 } );
    
     //$("#progress2_progress").html(Math.round((tls.progress() * 100)));
    var v = Math.round((tls.progress() * 100));
    
    
    }
*/
    function updateProgress() {
      var newPercent = Math.floor((tls.progress()*100));
      $('#count').text(newPercent + '%');
      }



    
    function someFunction() {
     // console.log("Hello world!");
      window.location.replace('/home');
    }



    
    
 
 
    
    
            $(".activemenuclass").on("click", function() {
      
              tls.restart();
    })
    
    
    
    
    
    
    
            $(".a.activemenuclass").on("click", function() {
            
             tls.kill();
        
    
    
    })

    $(".progresswrap").on("click", function() {
      gsap.set("#overlayme5", {delay: 0, width:"100%"});// overlay dog open
      gsap.set($(".progresswrap"), {delay: 0,visibility:"hidden"}); //change color tekst 

      var delayInMilliseconds = 1000; //1 second

      setTimeout(function() {
        window.location.replace('/home');
      }, delayInMilliseconds);


   
/*
      gsap.set("#overlayme5", {delay: 0, width:"100%"});// overlay dog open
      gsap.to($("#logo"), {delay: 0.5,visibility:"visible"}); //change color tekst 
       gsap.set("#logo", {delay: 0.5, className:"+=arrive"}); //tekst peolple open 
       gsap.to($(".menu-icon"), {delay: 0.5,visibility:"visible"}); //change color tekst 
       gsap.set($("body"), {delay: 1, backgroundColor:'white'}); // foto peolple 
       gsap.set($(".colorthe"), {delay: 0.5,color:"#000000"}); //change color tekst
       gsap.to("#box4", {delay: 1.7, opacity:1, translateY:0, skewY:0}); //tekst peolple open 
       
      */


  
     
     

})








    }) 

  



    }
    }
    
    
    
    
    
    
