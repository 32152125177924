<main>

    <div id="box5" class="box4 active"  >


<ul>  
  <li><p><strong>Project</strong></p>
    <p>Een webshop voor Grootzus. Francien Verhulst is oprichter van Grootzus. Ze maakt geboortekaarten en trouwkaarten met prachtige fantasierijke illustraties. </p></li>
  <li><p><strong>Klant</strong></p>
    <p>Grootzus</p></li>

<li><p><strong>Design</strong></p>
<p>Karlijne Brand</p></li>

<li><p><strong>Development</strong></p>
  <p>Xwebdevelopment</p>  </li>

  <li><p><strong>Url</strong></p>
    <p><a href="https://grootzus.nl" target="_blank">https://grootzus.nl</a></p>  </li>
    <li>
    </li>
    <li><p><strong>Visuele impressies</strong></p>
    </li>
</ul>

<img  class="images" src="./assets/img/grootzus1.jpg"/>
<img  class="images" src="./assets/img/grootzus2.jpg"/>
<video width="100%" autoplay="autoplay" onloadedmetadata="this.muted = true"  loop playsinline >
    <source src="./assets/img/grootzus1.mp4" type="video/mp4">
  </video>
<img  class="images" src="./assets/img/grootzus3.jpg"/>
<img  class="images" src="./assets/img/grootzus5.jpg"/>


</div></main>




