<main>

    <div id="box5" class="box4 active"  >


<ul>  
  <li><p><strong>Project</strong></p>
    <p>Een website voor Buurtcirkel. Een Buurtcirkel is een groepje mensen die bij elkaar in de wijk wonen. Ze kunnen elkaar helpen bij de dagelijkse dingen.</p></li>
  <li><p><strong>Klant</strong></p>
    <p>Pameijer</p></li>

<li><p><strong>Design</strong></p>
<p>Xwebdevelopment</p></li>

<li><p><strong>Illustratie</strong></p>
    <p>Studio Lonne Wennekendonk</p>  </li>

<li><p><strong>Development</strong></p>
  <p>Xwebdevelopment</p>  </li>

  <li><p><strong>Url</strong></p>
    <p><a href="https://buurtcirkel.nl" target="_blank">Https://buurtcirkel.nl</a></p>  </li>
    <li>
    </li>
    <li><p><strong>Visuele impressies</strong></p>
    </li>
</ul>

<img  class="images" src="./assets/img/buurtcirkel.jpg"/>
<img  class="images" src="./assets/img/buurtcirkel1.jpg"/>
<img  class="images" src="./assets/img/buurtcirkel2.jpg"/>
<img  class="images" src="./assets/img/buurtcirkel4.jpg"/>


</div></main>

