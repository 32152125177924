<main>

    <div id="box5" class="box4 active"  >


<ul>  
  <li><p><strong>Project</strong></p>
    <p>Blancgo is een intern project. Een website voor domeinregistratie & Wordpress webhosting.</p></li>
  <li><p><strong>Klant</strong></p>
    <p>Blancgo</p></li>

<li><p><strong>Design</strong></p>
<p>Xwebdevelopment</p></li>

<li><p><strong>Development</strong></p>
  <p>Xwebdevelopment</p>  </li>

  <li><p><strong>Url</strong></p>
    <p><a href="https://blancgo.nl" target="_blank">Https://blancgo.nl</a></p>  </li>
    <li>
    </li>
    <li><p><strong>Visuele impressies</strong></p>
    </li>
</ul>

<video width="100%" autoplay="autoplay" onloadedmetadata="this.muted = true"  loop playsinline >
    <source src="./assets/img/blancgo.mp4" type="video/mp4">
  </video>
<video width="100%" autoplay="autoplay" onloadedmetadata="this.muted = true"  loop playsinline >
  <source src="./assets/img/bla.mp4" type="video/mp4">
</video>
<img  class="images" src="./assets/img/choose.png"/>
<img  class="images" src="./assets/img/domein.jpg"/>

</div></main>

