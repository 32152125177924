<main>

    <div id="box5" class="box4 active"  >


<ul>  
  <li><p><strong>Project</strong></p>
    <p>Een website voor de organisatie Enforz. Enforz maakt deel uit van een netwerk van specialisten dat gericht is op het ontwikkelen van gezonde ondernemende organisaties. </p></li>
  <li><p><strong>Klant</strong></p>
    <p>Enforz</p></li>

<li><p><strong>Design</strong></p>
<p>Karlijne Brand</p></li>

<li><p><strong>Illustratie</strong></p>
    <p>Stang Gubbels</p>  </li>

<li><p><strong>Development</strong></p>
  <p>Xwebdevelopment</p>  </li>

  <li><p><strong>Url</strong></p>
    <p><a href="https://enforz.nl" target="_blank">Https://enforz.nl</a></p>  </li>
    <li>
    </li>
    <li><p><strong>Visuele impressies</strong></p>
    </li>
</ul>
<img  class="images" src="./assets/img/initiatieven.jpg"/>
<video width="100%" autoplay="autoplay" onloadedmetadata="this.muted = true"  loop playsinline >
    <source src="./assets/img/enforzy.mp4" type="video/mp4">
  </video>
  <img  class="images" src="./assets/img/enforz-locaties.jpg"/>
<video width="100%" autoplay="autoplay" onloadedmetadata="this.muted = true"  loop playsinline >
  <source src="./assets/img/hand.mp4" type="video/mp4">
</video>
<img  class="images" src="./assets/img/diensten.jpg"/>

<video width="100%" autoplay="autoplay" onloadedmetadata="this.muted = true"  loop playsinline >
    <source src="./assets/img/treintje.mp4" type="video/mp4">
  </video>

</div></main>


