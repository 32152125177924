<main>

    <div id="box5" class="box4 active"  >


<ul>  
  <li><p><strong>Project</strong></p>
    <p>Een website voor de Nederlandse Beroepsvereniging voor Wijkverpleging.</p></li>
  <li><p><strong>Klant</strong></p>
    <p>Voor de wijk</p></li>

<li><p><strong>Design</strong></p>
<p>Grphx</p></li>

<li><p><strong>Development</strong></p>
  <p>Xwebdevelopment</p>  </li>
    <li>
    </li>
    <li><p><strong>Visuele impressies</strong></p>
    </li>
</ul>

<img  class="images" src="./assets/img/voordewijk2.jpg"/>
<img  class="images" src="./assets/img/voordewijk1.jpg"/>
<img  class="images" src="./assets/img/voordewijk3.png"/>

</div></main>


