<main>

    <div id="box5" class="box4 active"  >

<video width="100%" autoplay="autoplay" oncanplay="this.play()"  loop playsinline>
    <source src="./assets/img/blancgo.mp4" type="video/mp4">
  </video>
<img  class="images" src="./assets/img/blancgo.jpg"/>

</div></main>

