<main>

    <div id="box5" class="box4 active"  >


<ul>  
  <li><p><strong>Project</strong></p>
    <p>Een website voor Mosterd&co. Zij creëren strategieën, concepten en communicatiecampagnes  voor organisaties die meerwaarde creëren voor mensen.

    </p></li>
  <li><p><strong>Klant</strong></p>
    <p>Mosterd&co</p></li>

<li><p><strong>Design</strong></p>
<p>Annemarie Mosterd</p></li>

<li><p><strong>Motion design</strong></p>
    <p>Tony Works</p>  </li>

<li><p><strong>Development</strong></p>
  <p>Xwebdevelopment</p>  </li>

  <li><p><strong>Url</strong></p>
    <p><a href="https://mosterdenco.nl" target="_blank">Https://mosterdenco.nl</a></p>  </li>
    <li>
    </li>
    <li><p><strong>Visuele impressies</strong></p>
    </li>
</ul>
<img  class="images" src="./assets/img/project.jpg"/>
<video width="100%" autoplay="autoplay" onloadedmetadata="this.muted = true"  loop playsinline >
    <source src="./assets/img/overons.mp4" type="video/mp4">
  </video>
  <img  class="images" src="./assets/img/projectenblok.jpg"/>
<video width="100%" autoplay="autoplay" onloadedmetadata="this.muted = true"  loop playsinline >
  <source src="./assets/img/trompet.mp4" type="video/mp4">
</video>


</div></main>



