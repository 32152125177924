<main>

    <div id="box5" class="box4 active"  >


<ul>  
  <li><p><strong>Project</strong></p>
    <p>Een website voor WineTank. WineTank is gebaseerd op een idee om een ​​"zero waste" wijnwinkel ervaring te creëren.</p></li>
  <li><p><strong>Klant</strong></p>
    <p>Winetank</p></li>

<li><p><strong>Design</strong></p>
<p>Xwebdevelopment</p></li>

<li><p><strong>Development</strong></p>
  <p>Xwebdevelopment</p>  </li>

  <li><p><strong>Url</strong></p>
    <p><a href="https://winetank.eu" target="_blank">Https://winetank.eu</a></p>  </li>
    <li>
    </li>
    <li><p><strong>Visuele impressies</strong></p>
    </li>
</ul>

<img  class="logoimg" src="./assets/img/logowinetankverticaal.jpg"/>
<img  class="images" src="./assets/img/wefrom.jpg"/>
<video width="100%" autoplay="autoplay" onloadedmetadata="this.muted = true"  loop playsinline >
    <source src="./assets/img/rethink.mp4" type="video/mp4">
  </video>

<video width="100%" autoplay="autoplay" onloadedmetadata="this.muted = true"  loop playsinline >
  <source src="./assets/img/spirit.mp4" type="video/mp4">
</video>
<img  class="images" src="./assets/img/winefooter.jpg"/>
</div></main>

