<main>

    <div id="box5" class="box4 active"  >


<ul>  
  <li><p><strong>Project</strong></p>
    <p>Een website voor Groosman architecten. GROOSMAN heeft disciplines als stedenbouw, architectuur, interieur, bouwmanagement, bouwkundige en constructieve engineering en BIM-management.</p></li>
  <li><p><strong>Klant</strong></p>
    <p>Groosman</p></li>

<li><p><strong>Design</strong></p>
<p>Lily Verhulst</p></li>

<li><p><strong>Development</strong></p>
  <p>Xwebdevelopment</p>  </li>

  <li><p><strong>Url</strong></p>
    <p><a href="https://groosman.com" target="_blank">Https://groosman.com</a></p>  </li>
    <li>
    </li>
    <li><p><strong>Visuele impressies</strong></p>
    </li>
</ul>

<img  class="images" src="./assets/img/groosman1.jpg"/>
<img  class="images" src="./assets/img/groosman2.jpg"/>
<img  class="images" src="./assets/img/groosman3.jpg"/>
<img  class="images" src="./assets/img/groosman4.jpg"/>
<img  class="images" src="./assets/img/groosman5.jpg"/>
<img  class="images" src="./assets/img/groosman6.jpg"/>

</div></main>


