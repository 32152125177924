<div id="momo"></div>
<div class="cover"></div>
<div class="cover1"></div>
<div class="cover2"></div>
<div class="cover3"></div>
<div id="overlayme"></div>
<div id="overlayme1"></div>
<div id="overlayme2"></div>
<div id="overlayme3"></div>
<div id="overlayme4"></div>
<div id="overlayme5"></div>
<div id="brandbox-containers">
<div id="brandbox">
  <video id="xwebvideo" preload='auto' width="100%" autoplay="autoplay" oncanplay="this.play()" onloadedmetadata="this.muted = true" loop muted playsinline>
    <source src="./assets/img/rotterdam.mp4" type="video/mp4">
  </video>

  <!--  <video loop muted autoplay playsinline style="pointer-events: none;" oncanplay="this.play()" onloadedmetadata="this.muted = true">
    <source src="./assets/img/rotterdam.mp4" type="video/mp4">
  </video> --> 
</div>
<div id="brandbox1"></div>
<div id="brandbox2"></div>
<div id="brandbox3"></div>
</div>



<div id="svgwrap" class="svgwrap">
  
<div id="wrap1" class="wrap1">
<svg version="1.1" id="Laag_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
   viewBox="0 0 68 88" style="enable-background:new 0 0 68 88;" xml:space="preserve">
<g>
  <path class="st9" d="M53.5,85.4L43.2,67.5c-4.2-6.8-6.8-11.3-9.4-16h-0.2c-2.3,4.7-4.6,9-8.8,16.1l-9.7,17.8h-12l24.7-40.9L4.1,4.5h12.1
    l10.7,19c3,5.3,5.3,9.4,7.4,13.7h0.4c2.3-4.8,4.3-8.5,7.3-13.7l11-19h12L40.4,43.9l25.2,41.5H53.5z"/>
</g>
</svg>
</div>
  
<div class="wrap2">
<svg version="1.1" id="Laag_2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
   viewBox="0 0 91 88" style="enable-background:new 0 0 91 88;" xml:space="preserve">
<g>
  <path class="st9" d="M13.1,27.3l7.7,29.5c1.7,6.5,3.2,12.5,4.3,18.5h0.4c1.3-5.9,3.2-12.1,5.2-18.4l9.5-29.6h8.9l9,29c2.2,7,3.8,13.1,5.2,19
    h0.4c1-5.9,2.5-12,4.4-18.8l8.3-29.2h10.4L67.9,85.4h-9.6l-8.9-27.7c-2-6.5-3.7-12.2-5.2-19.1H44c-1.4,7-3.2,13-5.3,19.2l-9.4,27.6
    h-9.6L2.3,27.3H13.1z"/>
</g>
</svg>
  </div>
  
  
<div class="wrap3">	
<svg version="1.1" id="Laag_3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
   viewBox="0 0 91 88" style="enable-background:new 0 0 91 88;" xml:space="preserve">
<g>
  <path class="st9" d="M14.7,58.3c0.2,14.3,9.4,20.2,19.9,20.2c7.6,0,12.1-1.3,16.1-3l1.8,7.6c-3.7,1.7-10.1,3.6-19.3,3.6
    c-17.9,0-28.6-11.8-28.6-29.3C4.7,39.8,15,26,31.9,26c19,0,24,16.7,24,27.4c0,2.2-0.2,3.8-0.4,4.9H14.7z M45.7,50.7
    c0.1-6.7-2.8-17.2-14.6-17.2c-10.7,0-15.4,9.8-16.2,17.2H45.7z"/>
</g>
</svg>
</div>
  
<div class="wrap4">	
<svg version="1.1" id="Laag_4" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
   viewBox="0 0 91 88" style="enable-background:new 0 0 91 88;" xml:space="preserve">
<g>
  <path class="st9" d="M8.4,85.4c0.2-4,0.5-9.8,0.5-15V0.2h10.4v36.5h0.2C23.3,30.2,30,26,39.3,26c14.4,0,24.6,12,24.5,29.6
    c0,20.8-13.1,31.1-26,31.1c-8.4,0-15.1-3.2-19.4-10.9H18l-0.5,9.6H8.4z M19.3,62.1c0,1.3,0.2,2.6,0.5,3.8c2,7.3,8.2,12.4,15.8,12.4
    c11,0,17.6-9,17.6-22.3c0-11.6-6-21.6-17.3-21.6c-7.2,0-13.9,4.9-16.1,13c-0.2,1.2-0.6,2.6-0.6,4.3V62.1z"/>
</g>
</svg>
</div>
    
<div class="wrap5">		
  <svg version="1.1" id="Laag_5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
   viewBox="0 0 91 88" style="enable-background:new 0 0 91 88;" xml:space="preserve">
<g>
  <path class="st9" d="M59,0.2v70.2c0,5.2,0.1,11,0.5,15H50l-0.5-10.1h-0.2C46.1,81.8,39,86.7,29.5,86.7c-14,0-24.8-11.9-24.8-29.5
    C4.5,37.9,16.5,26,30.7,26c8.9,0,14.9,4.2,17.5,8.9h0.2V0.2H59z M48.5,51c0-1.3-0.1-3.1-0.5-4.4c-1.6-6.7-7.3-12.2-15.2-12.2
    c-10.9,0-17.4,9.6-17.4,22.4c0,11.8,5.8,21.5,17.2,21.5c7.1,0,13.6-4.7,15.5-12.6c0.4-1.4,0.5-2.9,0.5-4.6V51z"/>
</g>
</svg>
</div>
  <div class="wrap6">		
  <svg version="1.1" id="Laag_6" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
   viewBox="0 0 91 88" style="enable-background:new 0 0 91 88;" xml:space="preserve">
<g>
  <path class="st9" d="M14.7,58.3c0.2,14.3,9.4,20.2,19.9,20.2c7.6,0,12.1-1.3,16.1-3l1.8,7.6c-3.7,1.7-10.1,3.6-19.3,3.6
    c-17.9,0-28.6-11.8-28.6-29.3C4.7,39.8,15,26,31.9,26c19,0,24,16.7,24,27.4c0,2.2-0.2,3.8-0.4,4.9H14.7z M45.7,50.7
    c0.1-6.7-2.8-17.2-14.6-17.2c-10.7,0-15.4,9.8-16.2,17.2H45.7z"/>
</g>
</svg>
</div>
  <div class="wrap7">		
<svg version="1.1" id="Laag_7" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
   viewBox="0 0 91 88" style="enable-background:new 0 0 91 88;" xml:space="preserve">
<g>
  <path class="st9" d="M12.9,27.3L24.3,60c1.9,5.3,3.5,10.1,4.7,14.9h0.4c1.3-4.8,3-9.6,4.9-14.9l11.3-32.6h11L33.8,85.4H23.7L1.7,27.3H12.9z"/>
</g>
</svg>
</div>
  <div class="wrap8">		
  <svg version="1.1" id="Laag_8" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
   viewBox="0 0 91 88" style="enable-background:new 0 0 91 88;" xml:space="preserve">
<g>
  <path class="st9" d="M14.7,58.3c0.2,14.3,9.4,20.2,19.9,20.2c7.6,0,12.1-1.3,16.1-3l1.8,7.6c-3.7,1.7-10.1,3.6-19.3,3.6
    c-17.9,0-28.6-11.8-28.6-29.3C4.7,39.8,15,26,31.9,26c19,0,24,16.7,24,27.4c0,2.2-0.2,3.8-0.4,4.9H14.7z M45.7,50.7
    c0.1-6.7-2.8-17.2-14.6-17.2c-10.7,0-15.4,9.8-16.2,17.2H45.7z"/>
</g>
</svg>
</div>
  <div class="wrap9">		
<svg version="1.1" id="Laag_9" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
   viewBox="0 0 91 88" style="enable-background:new 0 0 91 88;" xml:space="preserve">
<g>
  <path class="st9" d="M8.9,0.2h10.6v85.2H8.9V0.2z"/>
</g>
</svg>

</div>
  <div class="wrap10">		
<svg version="1.1" id="Laag_10" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
   viewBox="0 0 91 88" style="enable-background:new 0 0 91 88;" xml:space="preserve">
<g>
  <path class="st9" d="M61.4,55.9c0,21.5-14.9,30.8-28.9,30.8c-15.7,0-27.8-11.5-27.8-29.9C4.7,37.4,17.4,26,33.5,26C50.1,26,61.4,38.1,61.4,55.9
    z M15.3,56.5c0,12.7,7.3,22.3,17.6,22.3c10.1,0,17.6-9.5,17.6-22.6c0-9.8-4.9-22.3-17.4-22.3S15.3,45.4,15.3,56.5z"/>
</g>
</svg>
</div>
  <div class="wrap11">		
<svg version="1.1" id="Laag_11" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
   viewBox="0 0 91 112" style="enable-background:new 0 0 91 112;" xml:space="preserve">
<g>
  <path class="st9" d="M59,0.2v70.2c0,5.2,0.1,11,0.5,15H50l-0.5-10.1h-0.2C46.1,81.8,39,86.7,29.5,86.7c-14,0-24.8-11.9-24.8-29.5
    C4.5,37.9,16.5,26,30.7,26c8.9,0,14.9,4.2,17.5,8.9h0.2V0.2H59z M48.5,51c0-1.3-0.1-3.1-0.5-4.4c-1.6-6.7-7.3-12.2-15.2-12.2
    c-10.9,0-17.4,9.6-17.4,22.4c0,11.8,5.8,21.5,17.2,21.5c7.1,0,13.6-4.7,15.5-12.6c0.4-1.4,0.5-2.9,0.5-4.6V51z"/>
</g>
</svg>
</div>
  <div class="wrap12">		
<svg version="1.1" id="Laag_12" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
   viewBox="0 0 91 88" style="enable-background:new 0 0 91 88;" xml:space="preserve">
<g>
  <path class="st9" d="M2.9,43c0-6-0.1-10.9-0.5-15.7h9.2l0.5,9.4h0.4C15.7,31.2,21.1,26,30.7,26c7.9,0,13.9,4.8,16.4,11.6h0.2
    c1.8-3.2,4.1-5.8,6.5-7.6c3.5-2.6,7.3-4.1,12.8-4.1c7.7,0,19.1,5,19.1,25.2v34.2H75.5V52.5c0-11.2-4.1-17.9-12.6-17.9
    c-6,0-10.7,4.4-12.5,9.6c-0.5,1.4-0.8,3.4-0.8,5.3v35.9H39.2V50.6c0-9.2-4.1-16-12.1-16c-6.6,0-11.4,5.3-13.1,10.6
    c-0.6,1.6-0.8,3.4-0.8,5.2v35H2.9V43z"/>
</g>
</svg>
</div>
  <div class="wrap13">		
  <svg version="1.1" id="Laag_13" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
   viewBox="0 0 91 88" style="enable-background:new 0 0 91 88;" xml:space="preserve">
<g>
  <path class="st9" d="M14.7,58.3c0.2,14.3,9.4,20.2,19.9,20.2c7.6,0,12.1-1.3,16.1-3l1.8,7.6c-3.7,1.7-10.1,3.6-19.3,3.6
    c-17.9,0-28.6-11.8-28.6-29.3C4.7,39.8,15,26,31.9,26c19,0,24,16.7,24,27.4c0,2.2-0.2,3.8-0.4,4.9H14.7z M45.7,50.7
    c0.1-6.7-2.8-17.2-14.6-17.2c-10.7,0-15.4,9.8-16.2,17.2H45.7z"/>
</g>
</svg>
</div>
  <div class="wrap14">		
<svg version="1.1" id="Laag_14" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
   viewBox="0 0 91 88" style="enable-background:new 0 0 91 88;" xml:space="preserve">
<g>
  <path class="st9" d="M8.9,43c0-6-0.1-10.9-0.5-15.7h9.4l0.6,9.6h0.2C21.5,31.4,28.2,26,37.8,26c8,0,20.5,4.8,20.5,24.7v34.7H47.7V51.9
    c0-9.4-3.5-17.2-13.4-17.2c-7,0-12.4,4.9-14.2,10.8c-0.5,1.3-0.7,3.1-0.7,4.9v34.9H8.9V43z"/>
</g>
</svg>

</div>
    <div class="wrap15">		
<svg version="1.1" id="Laag_15" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
   viewBox="0 0 91 88" style="enable-background:new 0 0 91 88;" xml:space="preserve">
<g>
  <path class="st9" d="M21.6,10.6v16.7h15.1v8H21.6v31.3c0,7.2,2,11.3,7.9,11.3c2.8,0,4.8-0.4,6.1-0.7l0.5,7.9c-2,0.8-5.3,1.4-9.4,1.4
    c-4.9,0-8.9-1.6-11.4-4.4c-3-3.1-4.1-8.3-4.1-15.1V35.4h-9v-8h9V13.4L21.6,10.6z"/>
</g>
</svg>
</div>
</div>
<div id="box" class="box"><h1><span class="colorme">We creëren digitale</span> ervaringen.</h1></div>
<div id="box1" class="box1"><h1><span class="colorme">Een mooi resultaat volgens het </span> webdesign principes.</h1></div>
<div id="box2" class="box2"><h1><span class="colorme">We brengen identiteit naar het juiste</span> imago.</h1></div>
  <div id="box3" class="box3"><h1><span class="colorme">We bouwen aan een </span>kansrijk impact.</h1></div> 

 <!-- <div id="together">
  <div id="containerp"> </div>
  <div id="containerm"></div>
  <div id="containera"> </div>
  <div id="containerb"></div>
  <div id="containerc"> </div>
  <div id="containerd"></div>
  <div id="containere"> </div>
  <div id="containerf"></div>
  <div id="containerg"> </div>


      <div class="heart"></div>
    </div> -->

   

  <div class="progresswrap">
    <progress class="together" id="progress" max="100" value=""></progress>
       <!-- <div id="count"></div>
     <div id="progress2_progress"></div> 
    <svg id="wrap"  width="300" height="300">
       background 
      <svg>
        <circle cx="150" cy="150" r="130" style="stroke: #fff; stroke-width:10; fill:transparent"/>
        <circle cx="150" cy="150" r="115" style="fill:transparent"/>
        <path style="stroke: #333; stroke-dasharray:820; stroke-dashoffset:-820; stroke-width:14; fill:transparent" d="M150,150 m0,-130 a 130,130 0 0,1 0,260 a 130,130 0 0,1 0,-260">
          <animate attributeName="stroke-dashoffset" dur="40s" to="820" repeatCount="1"/>
        </path>
      </svg>

      
      
      
      
      
    </svg>
  --->

    <div id="circelwrap">
      <circle-progress
      [percent]="100"
      [radius]="20"
      [outerStrokeWidth]="2"
      [innerStrokeWidth]="8"
      [outerStrokeColor]="'#ffffff'"
      [innerStrokeColor]="'transperant'"
      [animation]="true"
      [animationDuration]="22000"

    ></circle-progress>
      </div>

   




  </div>
