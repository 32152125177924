import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-winetank',
  templateUrl: './winetank.component.html',
  styleUrls: ['./winetank.component.css']
})
export class WinetankComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
