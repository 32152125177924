import { Component, OnInit } from '@angular/core';
import { TweenLite,  TimelineMax, TimelineLite, Back, Power0,} from 'gsap'; 
import {ScrollTrigger } from 'gsap/ScrollTrigger.js';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import * as THREE from 'three';
import { OrbitControls} from 'three/examples/jsm/controls/OrbitControls.js';
import { PointsMaterial} from 'three/src/materials/PointsMaterial.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { XYZLoader} from 'three/examples/jsm/loaders/XYZLoader';
import { PRWMLoader} from 'three/examples/jsm/loaders/PRWMLoader';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js';




@Component({
  selector: 'app-basehome',
  templateUrl: './basehome.component.html',
  styleUrls: ['./basehome.component.css']
})


export class BasehomeComponent implements OnInit {
  selectedUrl = "assets/img/wat.jpg"
  tabIndex = 1 ;
  imgs = new Array();
  onTabClick(index){
        this.tabIndex = index;
   }



  constructor() {


    $( document ).ready(function() {
      var str =  navigator.userAgent;
      var n = str.search("SamsungBrowser");
       if (n  > 0){console.log("yesser");}
      
    /*  $('#c canvas').on('touchmove', function(evt){
        evt.preventDefault();
    });

      var wcanvas =  $("body").width();
      var wtalk =  wcanvas/10 - 40;
      var itself =  document.querySelector('canvas');
      itself.style.marginLeft = wtalk + "px";
      
   
      
      let camera, scene, renderer;
      
            init();
            animate();
      
            function init() {
      
              camera = new THREE.PerspectiveCamera( 33, window.innerWidth / window.innerHeight, 0.1, 100 );
              camera.position.z = 10;
              
      
              scene = new THREE.Scene();
              scene.background = new THREE.Color( 0, 0, 0 );
      
              renderer = new SVGRenderer();
              renderer.setSize( window.innerWidth, window.innerHeight );
              document.body.appendChild( renderer.domElement );
      
              //
      
              const vertices = [];
              const divisions = 50;
      
              for ( let i = 0; i <= divisions; i ++ ) {
      
                const v = ( i / divisions ) * ( Math.PI * 2 );
      
                const x = Math.sin( v );
                const z = Math.cos( v );
      
                vertices.push( x, 0, z );
      
              }
      
              const geometry = new THREE.BufferGeometry();
              geometry.setAttribute( 'position', new THREE.Float32BufferAttribute( vertices, 3 ) );
      
      
             for ( let i = 1; i <= 90; i ++ ) {
              const materials = new THREE.LineDashedMaterial( {
                color: 'green',
                linewidth: 1,
                dashSize: 100,
                gapSize: 1
              } );
              const lines = new THREE.Line( geometry, materials );
              lines.scale.setScalar( .2 );
              scene.add( lines );
      
            }
            /*
            var geometry1 = new THREE.BoxGeometry( 1, 1, 1 );
            var material = new THREE.MeshBasicMaterial( { color: 0xffffff } );
            var cube = new THREE.Mesh( geometry1, material );
            scene.add( cube );
      
             for ( let i = 1; i <= 8; i ++ ) {
              const material = new THREE.LineDashedMaterial( {
                color: 'red',
                linewidth: 3,
                dashSize: 100,
                gapSize: 10
              } );
              const line = new THREE.Line( geometry, material );
              line.scale.setScalar( 1 );
              scene.add( line );
      
            }
      
        
      
      
              window.addEventListener( 'resize', onWindowResize, false );
      
            }
      
            function onWindowResize() {
      
              camera.aspect = window.innerWidth / window.innerHeight;
              camera.updateProjectionMatrix();
      
              renderer.setSize( window.innerWidth, window.innerHeight );
      
            }
      
            function animate() {
      
              let count = 0;
              const time = performance.now() / 1000;
      
              scene.traverse( function ( child ) {
      
                child.rotation.x = count + ( time / 3 );
                child.rotation.z = count + ( time / 4 );
      
                count ++;
      
              } );
              
      
              renderer.render( scene, camera );
              requestAnimationFrame( animate );
      
            }
      
   
      
      let camera, scene, renderer, clock;
      
      let mesh;
      
      let sign = 1;
      const speed = 0.5;
      
      init();
      animate();
      
      function init() {
      
        const container = document.getElementById( 'container' );
      
        camera = new THREE.PerspectiveCamera( 33, window.innerWidth / window.innerHeight, 0.2, 100 );
        camera.position.set( 0, 5, 5 );
      
        scene = new THREE.Scene();
      
        clock = new THREE.Clock();
      
        const light1 = new THREE.PointLight( 0xC0C0C0, 0.7 );
        light1.position.set( 100, 100, 100 );
        scene.add( light1 );
      
        const light2 = new THREE.PointLight( 0x222121, 0.7 );
        light2.position.set( - 100, - 100, - 100 );
        scene.add( light2 );
      
      
      
      
      
        scene.add( new THREE.AmbientLight( 0x000000) );
      
        const loader = new GLTFLoader();
        loader.load( 'assets/gltf/AnimatedMorphSphere.gltf.json', function ( gltf ) {
      
          gltf.scene.traverse( function ( node ) {
      
            if (  (<any> node).isMesh ) mesh = node;
      
          } );
      
          mesh.material.morphTargets = true;
      
          mesh.rotation.z = Math.PI / 1.2;
      
          //mesh.material.visible = false;
      
         scene.add( mesh );


    




      
          //
      
          const pointsMaterial = new THREE.PointsMaterial( {
      
            size: 10,
            sizeAttenuation: false,
            map: new THREE.TextureLoader().load( 'assets/img/discs.png' ),
            alphaTest: 1,
            morphTargets: true
      
          } );

          const points = new THREE.Points( mesh.geometry, pointsMaterial );
      
          points.morphTargetInfluences = mesh.morphTargetInfluences;
          points.morphTargetDictionary = mesh.morphTargetDictionary;
      
          mesh.add( points );
      
        } );
      
        //
      
        renderer = new THREE.WebGLRenderer();
        renderer.setPixelRatio( window.devicePixelRatio );
        renderer.setSize( window.innerWidth, window.innerHeight );
        container.appendChild( renderer.domElement );
        
      
        //
      
        const controls = new OrbitControls( camera, renderer.domElement );
        controls.minDistance = 5;
        controls.maxDistance = 20;
        controls.enableZoom  = false;
    
       
      
        //
      
        window.addEventListener( 'resize', onWindowResize, false );
      
        document.addEventListener( 'visibilitychange', onVisibilityChange );
      
      }
      
      function onWindowResize() {
      
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
      
        renderer.setSize( window.innerWidth, window.innerHeight );
      
      }
      
      function onVisibilityChange() {
      
        if ( document.hidden === true ) {
      
          clock.stop();
      
        } else {
      
          clock.start();
      
        }
      
      }
      
      function animate() {
      
      
        requestAnimationFrame( animate );
        render();
      
      }
      
      function render() {
      
      
      
      
        
      
        const delta = clock.getDelta();
      
        if ( mesh !== undefined ) {
      
          const step = delta * speed;
      
          mesh.rotation.y += step;
      
          mesh.morphTargetInfluences[ 1 ] = mesh.morphTargetInfluences[ 1 ] + step * sign;
      
          if ( mesh.morphTargetInfluences[ 1 ] <= 0 || mesh.morphTargetInfluences[ 1] >= 1 ) {
      
            sign *= - 1;
      
          }
      
      
      
          
      
        }
      
      
        
      
        renderer.render( scene, camera );
      
      }
      
      
      */   
       /*

          let renderers;
        function main() {


          const renderer = new THREE.WebGLRenderer( { antialias: true } );
                renderer.setPixelRatio( window.devicePixelRatio );
                renderer.setSize( window.innerWidth, window.innerHeight );
                document.body.appendChild( renderer.domElement );

                const containers= document.getElementById( 'container' );
                renderers = new THREE.WebGLRenderer();
                renderers.setPixelRatio( window.devicePixelRatio );
                renderers.setSize( window.innerWidth, window.innerHeight );
                containers.appendChild( renderers.domElement ); 

               
              
          
        
          const fov = 75;
          const aspect = 2;  // the canvas default
          const near = 0.1;
          const far = 5;
          const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
          camera.position.z = 2;
        
          const scene = new THREE.Scene();

                  
          const light = new THREE.PointLight( 0xffffff, 1, 100, 1.1);
          light.position.set( 50, 50, 50 );
          scene.add( light );


         





        

          const light1 = new THREE.PointLight( 0x222121, 0.7 );
          light1.position.set( 100, 100, 100 );
          scene.add( light1 );
        
          const light2 = new THREE.PointLight( 0x222121, 0.7 );
          light2.position.set( - 100, - 100, - 100 );
          scene.add( light2 );

          scene.add( new THREE.AmbientLight( 0x000000) );
        */
         
        //MeshBasicMaterial
     /*     const material = new THREE.MeshPhongMaterial({
opacity: 0.5,
transparent: true,
            map: loader.load('/assets/img/Xset-4.jpg'),
          });*/
/*
           const boxWidth = 1;
          const boxHeight = 1;
          const boxDepth = 1;
          const geometry = new THREE.BoxGeometry(boxWidth, boxHeight, boxDepth);
        
          const cubes = [];  // just an array we can use to rotate the cubes
          const loader = new THREE.TextureLoader();

          const  material = ['/assets/img/hashtag-set.jpg',
          '/assets/img/mailset.jpg',
          '/assets/img/www-set.jpg',
          '/assets/img/vinger-set.jpg',
          '/assets/img/code-set.jpg',
          '/assets/img/Xset-4.jpg',]
          .map(pic => {return new THREE.MeshPhongMaterial({
            map: loader.load(pic),
          });
        });



          
          const cube = new THREE.Mesh(geometry, material);
          scene.add(cube);
          cubes.push(cube);  // moet cube om te zien 

    
        
          function resizeRendererToDisplaySize(renderer) {
            const canvas = renderer.domElement;
            const width = canvas.clientWidth;
            const height = canvas.clientHeight;
            const needResize = canvas.width !== width || canvas.height !== height;
            if (needResize) {
              renderer.setSize(width, height, false);
            }
            return needResize;
          }


          const controls = new OrbitControls( camera, renderers.domElement );
          controls.minDistance = 1;
          controls.maxDistance = 20;
          controls.enableZoom  = false;



        
          function render(time) {
            time *= 0.001;
        
            if (resizeRendererToDisplaySize(renderers)) {
              const canvas = renderers.domElement;
              camera.aspect = canvas.clientWidth / canvas.clientHeight;
              camera.updateProjectionMatrix();
            }
        
            cubes.forEach((cube, ndx) => {
              const speed = .2 + ndx * .1;
              const rot = time * speed;
              cube.rotation.x = rot;
              cube.rotation.y = rot;
            });
        
            renderers.render(scene, camera);
      
        
            requestAnimationFrame(render);
          }
        
          requestAnimationFrame(render);
        }
        
        main();

*/

        let camera, scene, renderer, clock;


        let points;
  
        init();
        animate();
  
        function init() {
  
          camera = new THREE.PerspectiveCamera( 50, window.innerWidth / window.innerHeight, 0.1, 100 );
         // camera.position.set( 20, 7, 10 );
  
          scene = new THREE.Scene();
          scene.add( camera );
          camera.lookAt( scene.position );
  
          clock = new THREE.Clock();
  
          const loader = new XYZLoader();
          loader.load( 'assets/img/anim.xyz', function ( geometry ) {
  
            geometry.center();
            

            

            const material = new THREE.PointsMaterial( { color: 0XDFFF00, size: 0.1 } );
  
            points = new THREE.Points( geometry, material );
            
            scene.add( points );
   
           function colorme(){
         var clr = {value:0}
    
            TweenMax.to(clr, 14, {
                value:1,
                repeat:-1,
                modifiers:{
                    value:function( v ) {
                        material.color.setHSL( v, 1, 0.5 )
                    
                    }
                },
                ease:Linear.easeNone,
               // onUpdate:renderer
            })
    
          }
          colorme();
  
          }
          
          
          
          
          
          
          );


                  
       
     
  
  
          //
         
          const container = document.getElementById( 'c' );
          renderer = new THREE.WebGLRenderer();
          // renderer.setClearColor( 0xffffff, 1 );
          renderer.setPixelRatio( window.devicePixelRatio );
          renderer.setSize( window.innerWidth, window.innerHeight );
          container.appendChild( renderer.domElement );


          
  
          //
        //  const controls = new OrbitControls( camera, renderer.domElement );
        //  controls.minDistance = 20;
         // controls.maxDistance = 20;
        //  controls.enableZoom  = false;

          
          var screenx = $(window);    
          if (screenx.width()< 801) {
            camera.position.set( 50, 7, 10 );
          const controls = new OrbitControls( camera, renderer.domElement );
          controls.minDistance = 50;
          controls.maxDistance = 50;
          controls.enableZoom  = false;
          controls.enableRotate= false;
          }

          var screenz = $(window);    
          if (screenz.width()>802) {
            camera.position.set( 20, 7, 10 );
          const controls = new OrbitControls( camera, renderer.domElement );
          controls.minDistance = 20;
          controls.maxDistance = 20;
          controls.enableZoom  = false;
          controls.enableRotate= false;
          }
  
  
          window.addEventListener( 'resize', onWindowResize, false );
  
        }
  
        function onWindowResize() {


          var screenx = $(window);    
          if (screenx.width()< 415) {
            camera.position.set( 50, 7, 10 );
          const controls = new OrbitControls( camera, renderer.domElement );
          controls.minDistance = 50;
          controls.maxDistance = 50;
          controls.enableZoom  = false;
          controls.enableRotate= false;
          }

          var screenz = $(window);    
          if (screenz.width()>416) {
            camera.position.set( 20, 7, 10 );
          const controls = new OrbitControls( camera, renderer.domElement );
          controls.minDistance = 20;
          controls.maxDistance = 20;
          controls.enableZoom  = false;
          controls.enableRotate= false;
          }
  

          
  
          camera.aspect = window.innerWidth / window.innerHeight;
          camera.updateProjectionMatrix();
  
          renderer.setSize( window.innerWidth, window.innerHeight );
  
        }
  
        function animate() {
  
          requestAnimationFrame( animate );
  
          const delta = clock.getDelta();
  
          if ( points ) {
  
            points.rotation.x += delta * 0.2;
            points.rotation.y += delta * 0.5;
  
          }
  
          renderer.render( scene, camera );
 
        }

        var heights = document.getElementById('c').clientHeight;
        document.getElementById("xsection").style.height = heights + 100  + "px";




        let cameras, scenes, renderers, clocks;


        let pointss;
  
        inits();
        animates();
  
        function inits() {
  
          cameras = new THREE.PerspectiveCamera( 50, window.innerWidth / window.innerHeight, 0.1, 1000000 );
         // camera.position.set( 20, 7, 10 );
  
          scenes = new THREE.Scene();
          scenes.add( cameras );
          cameras.lookAt( scenes.position );
  
          clocks = new THREE.Clock();
  
          const loaders = new XYZLoader();
          loaders.load( 'assets/img/animtest.xyz', function ( geometry ) {
  
            geometry.center();
            

            

            const materials = new THREE.PointsMaterial( { color: 0XDFFF00, size: 0.1 } );
  
            pointss = new THREE.Points( geometry, materials );
            
            scenes.add( pointss );
   
           function colorme(){
         var clr = {value:0}
    
            TweenMax.to(clr, 14, {
                value:1,
                repeat:-1,
                modifiers:{
                    value:function( v ) {
                        materials.color.setHSL( v, 1, 0.5 )
                    
                    }
                },
                ease:Linear.easeNone,
               // onUpdate:renderer
            })
    
          }
          colorme();
  
          }
          
          
          
          
          
          
          );


                  
       
     
  
  
          //
         
          const containers = document.getElementById( 'd' );
          renderers = new THREE.WebGLRenderer();
          // renderer.setClearColor( 0xffffff, 1 );
          renderers.setPixelRatio( window.devicePixelRatio );
          renderers.setSize( window.innerWidth, window.innerHeight );
          containers.appendChild( renderers.domElement );


          
  
          //
        //  const controls = new OrbitControls( camera, renderer.domElement );
        //  controls.minDistance = 20;
         // controls.maxDistance = 20;
        //  controls.enableZoom  = false;

          
          var screenxs = $(window);    
          if (screenxs.width()< 801) {
            cameras.position.set( 50, 7, 10 );
          const controlss = new OrbitControls( cameras, renderers.domElement );
          controlss.minDistance = 50;
          controlss.maxDistance = 50;
          controlss.enableZoom  = false;
          controlss.enableRotate= false;
          }

          var screenzs = $(window);    
          if (screenzs.width()>802) {
            cameras.position.set( 920, 7, 10 );
          const controlss = new OrbitControls( cameras, renderers.domElement );
          controlss.minDistance = 120;
          controlss.maxDistance = 120;
          controlss.enableZoom  = false;
          controlss.enableRotate= false;
          }
  
  
          window.addEventListener( 'resize', onWindowResizes, false );
  
        }
  
        function onWindowResizes() {


          var screenxs = $(window);    
          if (screenxs.width()< 415) {
            cameras.position.set( 50, 7, 10 );
          const controlss = new OrbitControls( cameras, renderers.domElement );
          controlss.minDistance = 50;
          controlss.maxDistance = 50;
          controlss.enableZoom  = false;
          controlss.enableRotate= false;
          }

          var screenzs = $(window);    
          if (screenzs.width()>416) {
            cameras.position.set( 20, 7, 10 );
          const controlss = new OrbitControls( cameras, renderers.domElement );
          controlss.minDistance = 20;
          controlss.maxDistance = 20;
          controlss.enableZoom  = false;
          controlss.enableRotate= false;
          }
  

          
  
          cameras.aspect = window.innerWidth / window.innerHeight;
          cameras.updateProjectionMatrix();
  
          renderers.setSize( window.innerWidth, window.innerHeight );
  
        }
  
        function animates() {
  
          requestAnimationFrame( animates );
  
          const deltas = clocks.getDelta();
  
          if ( pointss ) {
  
            pointss.rotation.x += deltas * 0.2;
            pointss.rotation.y += deltas * 0.5;
  
          }
  
          renderers.render( scenes, cameras );
 
        }

        var heightss = document.getElementById('d').clientHeight;
        document.getElementById("xsections").style.height = heightss + 100  + "px";


        
        



/*
        let rendererz;
        function mainz() {
        var scenez = new THREE.Scene();
        var cameraz = new THREE.PerspectiveCamera(75, window.innerWidth/window.innerHeight, 0.1,1000);
         
        var rendererz = new THREE.WebGLRenderer();
        const containerz= document.getElementById( 'container' );
        rendererz.setSize (window.innerWidth, window.innerHeight);
        containerz.appendChild(rendererz.domElement);

       /*  6 different side
      var mats =['/assets/img/Xset-4.jpg',
        '/assets/img/Xset-2.jpg',
        '/assets/img/Xset-4.jpg',
        '/assets/img/Xset-3.jpg',
        '/assets/img/Xset-4.jpg',
        '/assets/img/Xset-4.jpg',]
        .map(pic => {return new THREE.MeshBasicMaterial({
          map: loader.load(pic),
        });
      
        //  const material = new THREE.MeshBasicMaterial({
       //     map: loader.load('/assets/img/Xset-4.jpg'),
         });
          const cube = new THREE.Mesh(geometry, mats);



          //MeshPhongMaterial
        var geometry = new THREE.SphereGeometry(0.5, 32, 32);
        const loader = new THREE.TextureLoader();
        const material = new THREE.MeshPhongMaterial({
          map: loader.load('/assets/img/concreet.jpg'),
        });
        var sphere = new THREE.Mesh(geometry, material);
        var wireframe = new THREE.WireframeGeometry( geometry );
        
        const light = new THREE.PointLight( 0xffffff, 9, 100, 1.1);
light.position.set( 50, 50, 50 );
scenez.add( light );


        
        
        const controls = new OrbitControls( cameraz, rendererz.domElement );
        controls.minDistance = 2;
        controls.maxDistance = 10;
        controls.enableZoom  = false;
        controls.autoRotateSpeed = 0.05;
  
         
        var line = new THREE.LineSegments( wireframe );
        (<any>line.material).depthTest = false;
        //line.material.opacity = 0.25;
        (<any>line.material).transparent = false;
        
         scenez.add( sphere );
         
        cameraz.position.z= 2;
        //camera.position.x= 10;
         
        function renderz(){
          requestAnimationFrame (renderz);
         
          line.rotation.x += 0.01;
         line.rotation.y -= 0.01;
          line.rotation.z -= 0.01;
          //sphere.rotation.x += 0.02;
         sphere.rotation.y -= 0.01;
          rendererz.render (scenez, cameraz);
        }
        renderz();
        
          }

          mainz();

    */    
   }
   );
  }
/*
  slides = [
    {img: "http://placehold.it/350x150/000000"},
    {img: "http://placehold.it/350x150/111111"},
    {img: "http://placehold.it/350x150/333333"},
    {img: "http://placehold.it/350x150/666666"},
  ];

  slideConfig = {"slidesToShow": 1, "slidesToScroll": 1, "fade": true, "autoplay":false, "autoplaySpeed":8000, speed: 10,};
  
  addSlide() {
    this.slides.push({img: "http://placehold.it/350x150/777777"})
  }
  
  removeSlide() {
    this.slides.length = this.slides.length - 1;
  }
  
  slickInit(e) {
    console.log('slick initialized');
  }
  
  breakpoint(e) {
    console.log('breakpoint');
  }
  
  afterChange(e) {

  }
  
  beforeChange(e) {

  }


showDefinition = function showDefinition(e) {
  console.log("you've hovered over the id :" + e.target.id);
}
  

*/


  ngOnInit(): void {

    

  


    function isInViewport(el) {
      const rect = el.getBoundingClientRect();
      return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  
      );
  }
  
  

  gsap.utils.toArray(".color").forEach(function(elem) {

 
    var color = (<Element>elem).getAttribute('data-color');

   /*
    ScrollTrigger.create({
      trigger: elem,
      start:'top 50%',
      end:'bottom 50%',
      onEnter: () => gsap.to('body',3, {backgroundColor:color , ease:Cubic.easeOut}), 
      onLeave: () => gsap.to('body',0, {backgroundColor:'white', ease:Cubic.easeOut}), 
      onLeaveBack: () => gsap.to('body',0, {backgroundColor:'white', ease:Cubic.easeOut}), 
      onEnterBack: () => gsap.to('body',0, {backgroundColor:color , ease:Cubic.easeOut}), 

    });
*//*
    ScrollTrigger.create({
      trigger: elem,
      start:'top 7%',
      onEnter: () => gsap.to('.logo',.2, {backgroundColor:'white' , ease:Cubic.easeOut}), 
      onLeave: () => gsap.to('.logo',.2, {backgroundColor:color, ease:Cubic.easeOut}), 
      onLeaveBack: () => gsap.to('.logo',.2, {backgroundColor:color, ease:Cubic.easeOut}), 
      onEnterBack: () => gsap.to('.logo',.2, {backgroundColor:'white' , ease:Cubic.easeOut}), 
 
    });

    ScrollTrigger.create({
      trigger: elem,
      start:'top 7%',
      onEnter: () => gsap.to('.st10',.2, {fill:color , ease:Cubic.easeOut}), 
      onLeave: () => gsap.to('.st10',.2, {fill:'white', ease:Cubic.easeOut}), 
      onLeaveBack: () => gsap.to('.st10',.2, {fill:'white', ease:Cubic.easeOut}), 
      onEnterBack: () => gsap.to('.st10',.2, {fill:color , ease:Cubic.easeOut}), 
 
    });

    ScrollTrigger.create({
      trigger: elem,
      start:'top 7%',
      onEnter: () => gsap.to('.buttons span',.2, {color:'white' , ease:Cubic.easeOut}), 
      onLeave: () => gsap.to('.buttons span',.2, {color, ease:Cubic.easeOut}), 
      onLeaveBack: () => gsap.to('.buttons span',.2, {color, ease:Cubic.easeOut}), 
      onEnterBack: () => gsap.to('.buttons span',.2, {color:'white' , ease:Cubic.easeOut}), 

    });

    ScrollTrigger.create({
      trigger: elem,
      start:'top 7%',
      onEnter: () => gsap.to('#meme',.2, {backgroundColor:'white' , ease:Cubic.easeOut}), 
      onLeave: () => gsap.to('#meme',.2, {backgroundColor:color, ease:Cubic.easeOut}), 
      onLeaveBack: () => gsap.to('#meme',.2, {backgroundColor:color, ease:Cubic.easeOut}), 
      onEnterBack: () => gsap.to('#meme',.2, {backgroundColor:'white' , ease:Cubic.easeOut}), 

    });

    ScrollTrigger.create({
      trigger: elem,
      start:'top 30%',
      end:'bottom 35%',
      onEnter: () => gsap.to('#box5',.2, {backgroundColor:'white' , ease:Cubic.easeOut}), 
      onLeave: () => gsap.to('#box5',.2, {backgroundColor:color, ease:Cubic.easeOut}), 
      onLeaveBack: () => gsap.to('#box5',.2, {backgroundColor:color, ease:Cubic.easeOut}), 
      onEnterBack: () => gsap.to('#box5',.2, {backgroundColor:'white' , ease:Cubic.easeOut}), 
      markers:false
    });


    ScrollTrigger.create({
      trigger: elem,
      start:'top 40%',
      end:'bottom 60%',
      onEnter: () => gsap.to('#one',1, {delay: 0.4, width:"60%", ease:Cubic.easeOut}), 


  });

  ScrollTrigger.create({
    trigger: elem,
    start:'top 40%',
    end:'bottom 60%',
    onEnter: () => gsap.to('#two',1, {delay: 0.2, width:"69%",  ease:Cubic.easeOut}), 


});

ScrollTrigger.create({
  trigger: elem,
  start:'top 40%',
  end:'bottom 60%',
  onEnter: () => gsap.to('#threes',1, {delay: 0.2, height:"35%",  ease:Cubic.easeOut}), 


});

ScrollTrigger.create({
  trigger: elem,
  start:'top 40%',
  end:'bottom 60%',
  onEnter: () => gsap.to('#circelwrap',1, {delay: 0.2, opacity: 1, translateX: 0, ease:Cubic.easeOut}), 


});


ScrollTrigger.create({
  trigger: elem,
  start:'top 40%',
  end:'bottom 60%',
  onEnter: () => gsap.to('#one img',0.5, {delay: 1.6, opacity: 1, translateX: 0,  ease:Cubic.easeOut}), 


});
ScrollTrigger.create({
  trigger: elem,
  start:'top 40%',
  end:'bottom 60%',
  onEnter: () => gsap.to('#twos img',0.5, {delay: 1.6, opacity: 1, translateX: 0, ease:Cubic.easeOut}), 


});
ScrollTrigger.create({
  trigger: elem,
  start:'top 40%',
  end:'bottom 60%',
  onEnter: () => gsap.to('#three img',0.5, {delay: 1.6, opacity: 1, translateX: 0, ease:Cubic.easeOut}), 


});
/*
ScrollTrigger.create({
  trigger: elem,
  start:'top 90%',
  onEnter: () => gsap.to('.spacer',2, {delay: 0, backgroundImage: "linear-gradient(to top, #000000 100%, white 100%)", ease:Cubic.easeOut}), 


});

ScrollTrigger.create({
  trigger: elem,
  start:'top 90%',
  onEnter: () => gsap.to('.box4 h1',0, {delay: 0, color: "#ffffff", ease:Cubic.easeOut}), 


});
  */
/*
var widthz = $('.section2').innerHeight;

ScrollTrigger.create({
	trigger: ".holdMe",
  animation: gsap.fromTo('.resizeMe', {height: 10 }, {height:'800px' }),
  pin: true,
	start: 'top top',
	end: 'top top',
	scrub: 1, // I like the 1 sec delay, set to true for exact anime on scroll
	markers: true,
})

*/




   
  });



   
    $( "circle-progress" ).click(function() {
console.log('hallo;')


    });

    /* change color on scroll

    function logoSwitch () {
      $('.altLogo').each(function() {
        $(this).css('top',
          $('.startLogo').offset().top -  $(this).closest('.rowc').offset().top
        );
      });
    };
    
    $(document).scroll(function() {logoSwitch();});
    
    logoSwitch();
*/


if ("ontouchstart" in document.documentElement)
{


  var elementidee = document.getElementById("ideewrap");
  elementidee.classList.add("ok");

}
else
{


  $( ".element" ).mouseenter(function(e) {
   
    var dataAttribute = this.getAttribute('data-name');
    // replace "data-name" with your data attribute name
  
      document.getElementById("contents").innerHTML='<img src="' + dataAttribute + '">';
  
  
    setTimeout(function() {
      document.getElementById("contents").classList.add("mystyle");
    }, 50);
  
  
   
  });
  
  $( ".element" ).mouseleave(function(e) {
    //var dataAttributes= this.getAttribute('');
    // replace "data-name" with your data attribute name
  
    document.getElementById("contents").innerHTML=' ';
  
    document.getElementById("contents").classList.remove("mystyle");
  
    
  });
  
}
   









const plugins = [ScrollToPlugin];



$(function(){  

  var wrapper = $("#wrapper"),
      $menu = $("#menu"),
      $window = $(window);
      var o:any = "http://localhost:4200/about/";
      console.log(o);
  
  $menu.on("click","a", function(){
      var $this = $(this),
          href = $this.attr("href"),
          topY = $(href).offset().top;
     
      gsap.to($window, 1, {
          scrollTo:{
              y: topY, 
              autoKill: true
          }, 
          ease:Power3.easeOut 
       });


      // $( "#popyup" ).load( o, function() {});
     // document.getElementById("popyup").innerHTML="'<app-about></app-about>'";
       gsap.to('#wrapperright',0.7, {delay: 0, opacity: "0", ease:Power4.easeOut})
      gsap.to('body',1, {delay: 1.1, overflow: "hidden", ease:Power4.easeOut})
     // gsap.to('.popyup',1, {delay: 1.2, transform: "translate3d(0px, 0px, 0px)", ease:Power4.easeOut})

      var screen = $(window);    
      if (screen.width() > 1400) {
  

      gsap.to('#content li h3',1, {delay: 0.1,fontSize: "26px", ease:Power4.easeOut})
   

      }

      
      if (screen.width() > 800) {
  

      gsap.to('#content li h3',1, {delay: 0.1,fontSize: "22px", ease:Power4.easeOut})
      gsap.to('#wrapperleft',0.7, {delay: 0.7, marginLeft: "100px", ease:Power4.easeOut})

      }

      if (screen.width() < 800) {
  

        gsap.to('#content li h3',1, {delay: 0.1,fontSize: "20px", ease:Power4.easeOut})
        gsap.to('#wrapperleft',1, {delay: 1.2, marginLeft: "0px", ease:Power4.easeOut})
  
        }

      
    return false;
  }); 

  });

  $(function(){  

  
        var $menus = $("#popyup");
    $menus.on("click",".creturn", function(){
  

          

        // $( "#popyup" ).load( o, function() {});
       // document.getElementById("popyup").innerHTML="'<app-about></app-about>'";


        var screenx = $(window); 
        var screenxv = $(window).width()/2; 
        var screenxn = $(window).width(); 
        console.log(screenxv);
        if (screenx.width() > 1400) {
    
       // gsap.to('#content li h3',0.2, {delay: 1,fontSize: "30px", ease:Power4.easeOut})

        }

      

        if (screenx.width() > 801) {
    
         // gsap.to('#content li h3',0.4, {delay: 1.2,fontSize: "30px", ease:Power4.easeOut})
          gsap.to('#wrapperleft',0.5, {delay: 0.1, marginLeft: "300px", ease:Power4.easeOut})
          gsap.to('#wrapperright',0.5, {delay: 0, opacity: "1", ease:Power4.easeOut})
         gsap.to('body',0.7, {delay: 1.1, overflow: "scroll", ease:Power4.easeOut})
         gsap.to('.popyup',0.4, {delay: 0.1, transform: "translate3d("+  screenxv + "px, 0px, 0px)", ease:Power4.easeOut})
  
          }
          if (screenx.width() > 1024) {
    
          //  gsap.to('#content li h3',0.4, {delay: 1.2,fontSize: "30px", ease:Power4.easeOut})
            gsap.to('#wrapperleft',0.5, {delay: 0.1, marginLeft: "400px", ease:Power4.easeOut})
            gsap.to('#wrapperright',0.5, {delay: 0, opacity: "1", ease:Power4.easeOut})
           gsap.to('body',0.7, {delay: 1.1, overflow: "scroll", ease:Power4.easeOut})
           gsap.to('.popyup',0.4, {delay: 0.1, transform: "translate3d("+  screenxv + "px, 0px, 0px)", ease:Power4.easeOut})
 
           
    
            }

          if (screenx.width() < 801) {
            
            gsap.to('#wrapperleft',0.5, {delay: 1, marginLeft: "0px", ease:Power4.easeOut})
            gsap.to('#wrapperright',0.5, {delay: 0, opacity: "1", ease:Power4.easeOut})
           gsap.to('body',0.7, {delay: 1.1, overflow: "scroll", ease:Power4.easeOut})
           gsap.to('.popyup',0.4, {delay: 0.7, transform: "translate3d("+  screenxn + "px, 0px, 0px)", ease:Power4.easeOut})
        
    
            }
      return false;
   

  
  
}); 
  
      
    });


    
      // Add active class to the current button (highlight it)
      var header = document.getElementById("menu");
      var btns = header.getElementsByClassName("element");
      for (var i = 0; i < btns.length; i++) {
        btns[i].addEventListener("click", function() {
        var current = document.getElementsByClassName("active");
        current[1].className = current[1].className.replace(" active", "");
        this.className += " active";
        gsap.to('.popyup',0.4, {delay: 0.7, transform: "translate3d(0px, 0px, 0px)"})
        });
      }
    
      this.pload(
        "assets/img/voordewijk.jpg",
      "assets/img/winetank.jpg",
      "/assets/img/blancgo.jpg",
      "/assets/img/enforz1.jpg",
      "/assets/img/mosterdenco.jpg",
      "/assets/img/groosman.jpg",
      "/assets/img/taart.jpg",
      "/assets/img/buurtcirkel.jpg",
      "assets/img/grootzus1.jpg",

      "/assets/img/winetank.jpg",
      "/assets/img/winetank1.jpg",
      "/assets/img/winetank3.jpg",
      "/assets/img/winetank10.jpg",
      "/assets/img/winefooter.jpg",
      "/assets/img/vfi.jpg",
      "/assets/img/vfi1.jpg",
      "/assets/img/vfi2.jpg",
      "/assets/img/vfi11.jpg",
      "/assets/img/nextiabr1.jpg",
      "/assets/img/nextiabr2.jpg",
      "/assets/img/nextiabr3.jpg",
      "/assets/img/nextibar4.jpg",

      "/assets/img/voordewijk1.jpg",
      "/assets/img/voordewijk2.jpg",
      "/assets/img/voordewijk3.png",
      "/assets/img/wefrom.jpg",
      "assets/img/groosman1.jpg",
      "assets/img/groosman2.jpg",
      "assets/img/groosman3.jpg",
      "assets/img/groosman4.jpg",
      "assets/img/groosman5.jpg",
      "assets/img/groosman6.jpg",

      "assets/img/grootzus2.jpg",
      "assets/img/grootzus3.jpg",
      "assets/img/grootzus4.jpg",
      "assets/img/grootzus5.jpg",
      "/assets/img/initiatieven.jpg",
      "/assets/img/enforz1.jpg",
      "/assets/img/enforz2.jpg",
      "/assets/img/enforz3.jpg",
      "/assets/img/enforz-locaties.jpg",
      "/assets/img/diensten.jpg",
      "/assets/img/domein.jpg",
      "/assets/img/choose.png",
      "/assets/img/buurtcirkel1.jpg",
      "/assets/img/buurtcirkel2.jpg",
      "/assets/img/buurtcirkel4.jpg",
      "/assets/img/mosterdenco.jpg",
      "/assets/img/project.jpg",
      "/assets/img/projecten.jpg",
      "/assets/img/projectenblok.jpg",
      "/assets/img/template.png",
      "/assets/img/taart.jpg");



  
   
 
  }




  pload(...args: any[]):void {
    for (var i = 0; i < args.length; i++) {
      this.imgs[i] = new Image();
      this.imgs[i].src = args[i];
      console.log('loaded: ' + args[i]);
    }
  }

  
  

}


